import dayjs from 'dayjs';

// Import necessary localization files
import 'dayjs/locale/en-ca';
import 'dayjs/locale/es';

export function setupDateLocalization(locale: string) {
    if (locale === 'en-CA') {
        dayjs.locale('en-ca');
    } else if (locale === 'en-US') {
        dayjs.locale('en');
    } else if (locale === 'es-MX') {
        dayjs.locale('es');
    } else {
        dayjs.locale('en');
    }
}
