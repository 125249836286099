import {Button, Divider, Grid, Typography} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Customer from '../Customer/Customer';
import Appointment from '../Appointment/Appointment';
import Services from '../Services/Services';
import Transportation from '../Transportation/Transportation';
import Vehicle from '../Vehicle/Vehicle';
import {
    fetchAvailabilityDms, fetchNextAvailability,
    postAppointment
} from '../../redux/asyncThunkApiMethods';
import { batch } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import PopulateAppointmentObject from '../../util/PopulateAppointmentObject';
import AddressForm from "../Address/AddressForm";
import React, {useState} from "react";
import {
    setCanSubmitAddress,
    setDropoffAddress,
    setNextDate, setNextTime,
    setPickupAddress,
    setPickupServiceAddress,
    setSecondaryServiceAddress,
    setServiceAddress
} from "../../redux/slices/appointmentSlice";

import availabilityApiService from "../../services/api/availabilityApi.service";
import _ from "lodash";
import {addAlert, resetAlert} from "../../redux/slices/alertSlice";
import {loadGoogleMapsAPI} from "../Address/GoogleMapsApi";
import {useIntl} from "react-intl";
import {isValidAddress} from "../Address/AddressHelper";

const FormBody = (): JSX.Element => {
    const state = useAppSelector((state) => state);
    const { app, customer, services, vehicle, appointment, transportation, alert } = useAppSelector((state) => state);
    const {serviceAddresses} = useAppSelector((state) => state.appointment);
    const { countryOptions } = state.app.countryOptions;

    const dispatch = useAppDispatch();
    const [isAddressDifferent, setAddressDifferent] = useState(false);
    const [disabledAddressButton, setDisabledAddressButton] = useState(false)
    const intl = useIntl();

    const serviceAddressesAreValid = () => {
        if (transportation.transportation.includes('PREMIUM_CONCIERGE')) {
            if (serviceAddresses) {
                if (isAddressDifferent) {
                    if (serviceAddresses.length > 1) {
                        // @ts-ignore
                        return isValidAddress(serviceAddresses[0], countryOptions) && isValidAddress(serviceAddresses[1], countryOptions);
                    } else {
                        return false;
                    }
                } else {
                    return isValidAddress(serviceAddresses[0], countryOptions);
                }
            } else {
                return false;
            }
        } else if (transportation.transportation === 'MOBILE_SERVICE') {
            if (serviceAddresses) {
                return isValidAddress(serviceAddresses[0], countryOptions);
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const makeAvailabilityCall = async() => {
        const appointmentObject = PopulateAppointmentObject(state,services.selectedServices,transportation.transportation,
            appointment.pickupAddress, appointment.dropoffAddress, appointment.serviceAddresses);
        const response = await availabilityApiService.getNextAvailability({
            bac: app.bac,
            country: app.country,
            appointment: appointmentObject,
        });
        if (response.vendorErrorMessage === null) {

            if (response.appointmentAvailabilities.DEFAULT || response.appointmentAvailabilities.EXPRESS_SERVICE) {
                const availableDates = _.union(
                    response.appointmentAvailabilities.DEFAULT,
                    response.appointmentAvailabilities.EXPRESS_SERVICE
                );
                const firstAvailableDate = availableDates.length ? availableDates[0].date : '';
                const firstAvailableTime = availableDates.length && availableDates[0].time.length > 0 ? availableDates[0].time[0] : '';
                batch(() => {
                    dispatch(setCanSubmitAddress(true));
                    dispatch(setNextDate(firstAvailableDate));
                    dispatch(setNextTime(firstAvailableTime));
                    dispatch(
                        fetchAvailabilityDms({
                            appointment: appointmentObject,
                            bac: app.bac,
                            country: app.country,
                            days: `${1}`,
                            startDate: firstAvailableDate
                        })
                    );
                });
            }
        } else {
            dispatch(
                fetchNextAvailability({
                    bac: app.bac,
                    country: app.country,
                    appointment: appointmentObject,
                })
            );
        }
    }

    // Convert meters to miles if USE_MILES is true
    // Convert meters to km if USE_MILES is false
    const convertDistanceInMetersToCorrectUnits = (distanceInMeters: number) => {
        if (countryOptions.USE_MILES) {
            return distanceInMeters * 0.000621;
        } else {
            return distanceInMeters / 1000;
        }
    }

    const performRangeCheck = (pickUpAddress: string, dropOffAddress: string, dealerRange:number) => {
        let allAddresses: string[];
        if (isAddressDifferent) {
            allAddresses = [pickUpAddress, dropOffAddress];
        } else {
            allAddresses = [pickUpAddress];
        }
        let rangeErrorMessage = intl.formatMessage({id: "address.error.range"});
        rangeErrorMessage = rangeErrorMessage ?  rangeErrorMessage : "ADDRESS_ERROR_RANGE";

        let dealer_addr = app.dealerAddress;
        loadGoogleMapsAPI().then(() => {
            const service = new window.google.maps.DistanceMatrixService(); // instantiate Distance Matrix service
            const geocoder = new window.google.maps.Geocoder();

            // Callback function used to process Distance Matrix response
            async function callback(response: any, status: any) {
                if (status !== "OK") {
                    return;
                }
                console.log(response);
                const pickupDistance = convertDistanceInMetersToCorrectUnits(response.rows[0].elements[0].distance.value);
                if (pickupDistance > dealerRange) {
                    dispatch(addAlert({ severity: 'error', message: rangeErrorMessage, type: '' }));
                } else {
                    if (response.rows[0].elements.length > 1) { // multiple Google address
                        const dropoffDistance = convertDistanceInMetersToCorrectUnits(response.rows[0].elements[1].distance.value);
                        if (dropoffDistance > dealerRange) { // dropoff is out of range
                            dispatch(addAlert({ severity: 'error', message: rangeErrorMessage, type: '' }));
                        } else {
                            await makeAvailabilityCall();
                        }
                    } else { // single Google address in range
                        await makeAvailabilityCall();
                    }
                }
            }

            // Geocode the address for the dealer
            geocoder.geocode({
                'address': dealer_addr
            }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK && results && results.length > 0) {

                    // set it to the correct, formatted address if it's valid
                    dealer_addr = results && results.length > 0 ? results[0].formatted_address : "";


                    if (dealer_addr) {
                        const matrixOptions = {
                            origins: [dealer_addr], // dealer location
                            destinations: allAddresses, // customer address
                            travelMode: google.maps.TravelMode.DRIVING,
                            unitSystem: google.maps.UnitSystem.IMPERIAL
                        };
                        // Call Distance Matrix service
                        service.getDistanceMatrix(matrixOptions, callback);


                    }
                }
            });
        });



    }

    const submitAddress = async() => {
        setDisabledAddressButton(true);
        if (transportation.transportation.includes('PREMIUM_CONCIERGE')) {
            const selectedTransportationOption = transportation.transportationOptions ? transportation.transportationOptions.find(transportationOption => transportationOption.code === transportation.transportation) : '';
            if (selectedTransportationOption) { // selectedTransportationOption must exist

                if (!selectedTransportationOption.vendorEnabled && selectedTransportationOption.range) {
                    const rangeThreshold = Number(selectedTransportationOption.range);
                    performRangeCheck(appointment.pickupAddress, appointment.dropoffAddress, rangeThreshold);
                } else {
                    await makeAvailabilityCall();
                }
            }

        } else {
            await makeAvailabilityCall();
        }
        setDisabledAddressButton(false);
    }

    const submitAppointment = () => {
        const appointmentObject = PopulateAppointmentObject(state,services.selectedServices,transportation.transportation,
            appointment.pickupAddress, appointment.dropoffAddress, appointment.serviceAddresses);
        batch(() => {
            dispatch(
                postAppointment({
                    bac: app.bac,
                    country: app.country,
                    appointment: appointmentObject,
                    availabilityGuid: appointment.availabilityGuid,
                })
            );
        });
    };

    const handleOnChangeDifferentAddress = () => {
        if (transportation.transportation.includes('PREMIUM_CONCIERGE')) {
            if (isAddressDifferent) { // from different to same
                const newPickUpAddress = (serviceAddresses && serviceAddresses?.length > 0) ? serviceAddresses.filter((item) => item.type !== 'DROP_OFF') : serviceAddresses;
                if (serviceAddresses && isValidAddress(serviceAddresses[0], countryOptions)) {
                    batch(() => {
                        dispatch(resetAlert());
                        dispatch(setDropoffAddress(''));
                        dispatch(setServiceAddress(newPickUpAddress))
                    });
                } else {
                    batch(() => {
                        dispatch(resetAlert());
                        dispatch(setDropoffAddress(''));
                        dispatch(setServiceAddress(newPickUpAddress))
                    });
                }
            } else {
                if (serviceAddresses && serviceAddresses[0].type !== "PICK_UP") {
                    batch(() => {
                        dispatch(resetAlert());
                        dispatch(setServiceAddress([
                            {
                                type: 'PICK_UP',
                                addressLine1: '',
                                cityName: '',
                                countrySubentity: '',
                                countrySubentityCode: '',
                                postalZone: '',
                            },
                        ]));
                        dispatch(setCanSubmitAddress(false));
                    });
                }
            }
            setAddressDifferent(!isAddressDifferent)
        }

    };

    const handleChangeInAddress = (data: any, differentAddress:boolean, id:string) => {

        if (data.isValidAddress) {
            if(transportation.transportation.includes('PREMIUM_CONCIERGE') ){
                const derivedAddress = data.address ? [data.address.address1, data.address.city, data.address.state, data.address.postalCode]
                    .filter(Boolean)
                    .join(", ") : "";
                dispatch(id==='DROP_OFF' ? setDropoffAddress(derivedAddress):setPickupAddress(derivedAddress));
            }
            if (transportation.transportation.includes('PREMIUM_CONCIERGE') && id === 'DROP_OFF') { // dropoff address
                const country = data.address.country ? data.address.country : "US";

                batch(() => {
                    dispatch(setSecondaryServiceAddress({
                        type: id,
                        addressLine1: data.address.address1,
                        cityName: data.address.city,
                        countrySubentity: data.address.state,
                        countrySubentityCode: country + "-" + data.address.state,
                        postalZone: data.address.postalCode
                    }));
                })
                if (serviceAddresses && isValidAddress(serviceAddresses[0], countryOptions)) {
                    dispatch(resetAlert());
                } else {
                    dispatch(setCanSubmitAddress(false));
                }

            } else {
                if (!transportation.transportation.includes('PREMIUM_CONCIERGE') || !differentAddress) { // same address
                    batch(() => {
                        dispatch(setServiceAddress([{
                            type: id,
                            addressLine1: data.address.address1,
                            cityName: data.address.city,
                            countrySubentity: data.address.state,
                            countrySubentityCode: data.address.country + "-" + data.address.state,
                            postalZone: data.address.postalCode
                        }]));
                    })

                } else { // address is different
                    batch(() => {
                        dispatch(setPickupServiceAddress({
                            type: id,
                            addressLine1: data.address.address1,
                            cityName: data.address.city,
                            countrySubentity: data.address.state,
                            countrySubentityCode: data.address.country + "-" + data.address.state,
                            postalZone: data.address.postalCode
                        }));
                    })
                }
                dispatch(resetAlert());
            }

        } else {
            dispatch(setCanSubmitAddress(false));
        }
    }


    return (
        <Grid container data-testid="form-body" item justifyContent="center" lg={6} md={8} spacing={5}>
            <Grid item xs={12}>
                <Typography variant="h3">
                    <FormattedMessage id="formBody.header" />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Customer />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Vehicle />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Services />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Transportation />
            </Grid>
            <Grid item xs={12}>
                {(transportation.transportation.includes('PREMIUM_CONCIERGE') ||
                    transportation.transportation === 'MOBILE_SERVICE') && <Divider />}
            </Grid>

            <Grid item xs={12}>
                {transportation.transportation === 'MOBILE_SERVICE' && <AddressForm onChange={(data)=> {handleChangeInAddress(data, isAddressDifferent, "SERVICE")}} id="SERVICE"/>}
                {transportation.transportation.includes('PREMIUM_CONCIERGE') &&
                    <div>
                        <AddressForm onChange={(data)=> {handleChangeInAddress(data, isAddressDifferent, "PICK_UP")}} id="PICK_UP"/>
                        <br/>
                        <br/>
                        <label>
                            <input type="checkbox" checked={isAddressDifferent} onChange={handleOnChangeDifferentAddress}/>
                            <FormattedMessage id="address.label.differentLocation"/>
                        </label>

                        <div>
                            {isAddressDifferent &&
                                <div>
                                    <br/>
                                    <br/>
                                    <AddressForm onChange={(data)=> {handleChangeInAddress(data, isAddressDifferent, "DROP_OFF")}} id="DROP_OFF"/>
                                </div>}
                        </div>

                    </div>}
            </Grid>
            <Grid item xs={6}>
                {(transportation.transportation.includes('PREMIUM_CONCIERGE') || transportation.transportation === 'MOBILE_SERVICE') && <Button
                    className="gds-button-primary"
                    data-testid="submit-address-button"
                    fullWidth
                    disabled={
                         !(serviceAddressesAreValid()) || alert.alert.severity === 'error' || disabledAddressButton
                    }
                    onClick={submitAddress}
                >
                    {appointment.pickupAddress ? "Update Address":"Save Address"}
                </Button>}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Appointment />
            </Grid>
            <Grid item xs={6}>
                <Button
                    className="gds-button-primary"
                    data-testid="submit-button"
                    disabled={
                        !(customer.isValid && services.isValid && vehicle.isValid && appointment.isValid && appointment.canSubmitAddress) ||
                        app.submissionSuccessful || alert.alert.severity === 'error'
                    }
                    fullWidth
                    type="submit"
                    onClick={submitAppointment}
                >
                    {!app.isSubmittingAppointment ? (
                        <FormattedMessage id="submitButton.label" />
                    ) : (
                        <FormattedMessage id="submitButton.label.submitting" />
                    )}
                </Button>
            </Grid>
        </Grid>
    );
};

export default FormBody;
