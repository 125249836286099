import { createTheme, Theme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { IntlProvider } from 'react-intl';
import { AccelerateThemeProvider } from 'react-material-theme';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import LanguageOptions from './constants/LanguageOptions';
import { useAppSelector } from './redux/hooks';
import AppStore from './redux/store';
import getThemeOverride from './styles/theme-override';

const AppProvidersWrapper = ({ children }: { children: JSX.Element }): JSX.Element => {
    const appState = useAppSelector((state) => state.app);

    return (
        <BrowserRouter>
            <IntlProvider
                messages={
                    LanguageOptions.find((option) => {
                        return option.locale === appState.locale;
                    })?.fileName
                }
                locale={appState.locale}
                defaultLocale="en-US"
            >
                <AccelerateThemeProvider>
                    <ThemeProvider theme={(theme: Theme) => createTheme(getThemeOverride(theme))}>
                        {children}
                    </ThemeProvider>
                </AccelerateThemeProvider>
            </IntlProvider>
        </BrowserRouter>
    );
};

const Root = ({ children }: { children: JSX.Element }): JSX.Element => {
    return (
        <Provider store={AppStore}>
            <AppProvidersWrapper>{children}</AppProvidersWrapper>
        </Provider>
    );
};

export default Root;
