import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import appointmentApiService, { AppointmentParams } from '../services/api/appointmentApi.service';
import availabilityApiService, {
    AvailabilityParams,
    DmsAvailabilityParams,
    NextAvailabilityParams,
} from '../services/api/availabilityApi.service';
import countryOptionsApiService, { CountryOptionsParams } from '../services/api/countryOptionsApi.service';
import optionsApiService, { OptionsParams } from '../services/api/optionsApi.service';
import servicesApiService, { ServicesParams } from '../services/api/servicesApi.service';
import summaryApiService, { SummaryParams } from '../services/api/summaryApi.service';
import ymmApiService, { YmmParams } from '../services/api/ymmApi.service';
import { Appointment } from '../types/Appointment.type';
import { Availability } from '../types/Availability.type';
import { CountryOptions } from '../types/CountryOptions.type';
import { Options } from '../types/Options.type';
import { Services } from '../types/Services.type';
import { Summary } from '../types/Summary.type';
import { Years, Models, MakesFiltered, Make } from '../types/Ymmv.type';

// @ts-ignore
export const fetchAvailability = createAsyncThunk<
    Availability,
    AvailabilityParams,
    { rejectValue: { message: string } }
>(
    // @ts-ignore
    'appointment/availability', async (params: AvailabilityParams, thunkApi) => {
        try {
            const response = await availabilityApiService.getAvailability(params);
            if (response.vendorErrorMessage === null) {
                return response;
            } else if (response.vendorErrorMessage.onTracError) {
                return thunkApi.rejectWithValue({ message: response.vendorErrorMessage.errorMessage });
            }
        } catch (error) {
            return thunkApi.rejectWithValue({
                message: 'alert.error.availability',
            });
        }
    }
);

export const fetchNextAvailability = createAsyncThunk<
    Availability,
    NextAvailabilityParams,
    { rejectValue: { message: string } }
    //@ts-ignore
>('services/nextAvailability', async (params: NextAvailabilityParams, thunkApi) => {
    try {
        const response = await availabilityApiService.getNextAvailability(params);
        if (response.vendorErrorMessage === null) {
            return response;
        } else if (response.vendorErrorMessage.onTracError) {
            return thunkApi.rejectWithValue({ message: response.vendorErrorMessage.errorMessage });
        }
    } catch (error) {
        return thunkApi.rejectWithValue({
            message: 'alert.error.availability',
        });
    }
});

export const fetchNextAvailabilityDms = createAsyncThunk<
    any,
    NextAvailabilityParams,
    { rejectValue: { message: string } }
>('appointment/nextAvailabilityDms', async (params: NextAvailabilityParams, thunkApi) => {
    try {
        const { appointment, bac, country } = params;
        const nextAvailabilityResponse = await thunkApi.dispatch(
            fetchNextAvailability({
                appointment: appointment,
                bac: bac,
                country: country,
            })
        );
        if (nextAvailabilityResponse.payload && nextAvailabilityResponse.meta.requestStatus === 'rejected') {
            // @ts-ignore
            if (nextAvailabilityResponse.payload.vendorErrorMessage.onTracError) {
                // @ts-ignore
                return thunkApi.rejectWithValue({message: nextAvailabilityResponse.payload.vendorErrorMessage.errorMessage,
                });
            }
        }
        if (nextAvailabilityResponse.payload && nextAvailabilityResponse.meta.requestStatus === 'fulfilled') {
            const availability = nextAvailabilityResponse.payload as Availability;

            if (
                availability.appointmentAvailabilities.DEFAULT ||
                availability.appointmentAvailabilities.EXPRESS_SERVICE
            ) {
                const availableDates = _.union(
                    availability.appointmentAvailabilities.DEFAULT,
                    availability.appointmentAvailabilities.EXPRESS_SERVICE
                );

                const nextDate = availableDates[0].date;
                const res = await thunkApi.dispatch(
                    fetchAvailability({
                        appointment: appointment,
                        bac: bac,
                        country: country,
                        days: `${1}`,
                        startDate: nextDate,
                    })
                );

                if (res.meta.requestStatus === 'rejected') {
                    return thunkApi.rejectWithValue({
                        message: 'alert.error.availability',
                    });
                }
            }
        }

        return nextAvailabilityResponse;
    } catch (error) {
        return thunkApi.rejectWithValue({
            message: 'alert.error.availability',
        });
    }
});

export const fetchAvailabilityDms = createAsyncThunk<any, DmsAvailabilityParams, { rejectValue: { message: string } }>(
    'appointment/availabilityDms',
    async (params: DmsAvailabilityParams, thunkApi) => {
        try {
            const { appointment, bac, country, days, startDate} = params;
            const availabilityResponse = await thunkApi.dispatch(
                fetchAvailability({
                    appointment,
                    bac,
                    country,
                    days,
                    startDate,
                })
            );

            return availabilityResponse;
        } catch (error) {
            return thunkApi.rejectWithValue({
                message: 'alert.error.availability',
            });
        }
    }
);

export const fetchCountryOptions = createAsyncThunk<
    CountryOptions,
    CountryOptionsParams,
    { rejectValue: { message: string } }
>('countryOptions/fetch', async (params: CountryOptionsParams, thunkApi) => {
    try {
        const response = await countryOptionsApiService.getCountryOptions(params);
        return response;
    } catch (error) {
        return thunkApi.rejectWithValue({
            message: 'alert.error.countryOptions',
        });
    }
});

export const fetchSummary = createAsyncThunk<Summary, SummaryParams, { rejectValue: { message: string } }>(
    'summary/fetch',
    async (params: SummaryParams, thunkApi) => {
        try {
            const response = await summaryApiService.getSummary(params);
            return response;
        } catch (error) {
            return thunkApi.rejectWithValue({
                message: 'alert.error.summary',
            });
        }
    }
);

// @ts-ignore
export const postAppointment = createAsyncThunk<
    Appointment | null,
    AppointmentParams,
    { rejectValue: { message: string } }
>('services/appointment', async (params: AppointmentParams, thunkApi) => {
    try {
        const response = await appointmentApiService.setAppointment(params);
        console.log('RESPONSE: ', response);
        // @ts-ignore
        if (response.status === 200 && response.data) {
            return response;
        }
        //@ts-ignore
        else if (response.data.message?.includes('Unable to assign advisor to appointment: Date/time not available.')) {
            // @ts-ignore
            return thunkApi.rejectWithValue({ message: response.data.message });
        }
        //@ts-ignore
        else if (response.data.message?.includes('Unable to assign van to appointment:')) {
            // @ts-ignore
            return thunkApi.rejectWithValue({ message: response.data.message });
        } else {
            return thunkApi.rejectWithValue({
                message: 'alert.error.appointment',
            });
        }
    } catch (error) {
        console.log('ERROR: ' + error);
        return thunkApi.rejectWithValue({
            message: 'alert.error.appointment',
        });
    }
});

export const fetchServices = createAsyncThunk<Services, ServicesParams, { rejectValue: { message: string } }>(
    'services/fetch',
    async (params: ServicesParams, thunkApi) => {
        try {
            const response = await servicesApiService.getServices(params);
            return response;
        } catch (error) {
            return thunkApi.rejectWithValue({
                message: 'alert.error.services',
            });
        }
    }
);

export const fetchTransportationOptions = createAsyncThunk<
    Options,
    OptionsParams,
    { rejectValue: { message: string } }
>('options/fetch', async (params: OptionsParams, thunkApi) => {
    try {
        const response = await optionsApiService.getOptions(params);
        return response;
    } catch (error) {
        return thunkApi.rejectWithValue({
            message: 'alert.error.transportation',
        });
    }
});

export const fetchYears = createAsyncThunk<Years, YmmParams, { rejectValue: { message: string } }>(
    'years/fetch',
    async (params: YmmParams, thunkApi) => {
        try {
            let response = await ymmApiService.getYears(params);
            response.year = _.reverse(_.sortBy(response.year));
            return response;
        } catch (error) {
            return thunkApi.rejectWithValue({
                message: 'alert.error.years',
            });
        }
    }
);

export const fetchModels = createAsyncThunk<Models, YmmParams, { rejectValue: { message: string } }>(
    'models/fetch',
    async (params: YmmParams, thunkApi) => {
        try {
            const response = await ymmApiService.getModels(params);
            return response;
        } catch (error) {
            return thunkApi.rejectWithValue({
                message: 'alert.error.models',
            });
        }
    }
);

export const fetchMakes = createAsyncThunk<MakesFiltered, YmmParams, { rejectValue: { message: string } }>(
    'makes/fetch',
    async (params: YmmParams, thunkApi) => {
        try {
            const response = await ymmApiService.getMakes(params);
            const gm = response.make.filter((item: Make) => item.gm);
            const other = response.make.filter((item: Make) => !item.gm);
            const filteredResponse = { gm, other };
            return filteredResponse;
        } catch (error) {
            return thunkApi.rejectWithValue({
                message: 'alert.error.makes',
            });
        }
    }
);
