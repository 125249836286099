import axios from 'axios';
import { Appointment } from '../../types/Appointment.type';

const baseURL = process.env.REACT_APP_APPOINTMENTS_BASE_URL;
const serviceName = 'appointments';
const api = 'api';

export type AppointmentParams = {
    bac: string;
    country: string;
    appointment: Appointment;
    availabilityGuid: string | undefined;
};

const appointmentApiService = {
    setAppointment: (params: AppointmentParams): Promise<Appointment> => {
        const url = `${baseURL}/${api}/${serviceName}/${params.bac}?availabilityGuid=${params.availabilityGuid}`;

        return axios.post(url, params.appointment, { validateStatus: () => true });
    },
};

export default appointmentApiService;
