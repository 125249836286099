import { batch } from 'react-redux';
import { ChangeEvent, useCallback } from 'react';
import { Box, Grid, FormHelperText, InputAdornment, Typography, MenuItem, InputLabel } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, TextField } from 'formik-material-ui';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as yup from 'yup';
import ErrorIcon from '@material-ui/icons/Error';
import { HandleFormikChange, SetFormikFieldValue } from '../../types/Formik.type';
import { setIsValidVehicle, setMake, setMileage, setModel, setVin, setYear } from '../../redux/slices/vehicleSlice';
import { fetchMakes, fetchModels, fetchServices } from '../../redux/asyncThunkApiMethods';
import { setEnableServicesSection, setPrefilledParams } from '../../redux/slices/appSlice';
import { isValidVin } from '../../util/Validation/Vin';
import { MENU_PROPS } from '../../constants/MaterialUiConfig';
import globalStyles from '../App.module.scss';
import styles from './Vehicle.module.scss';
import { resetServices, setAreValidServices } from '../../redux/slices/servicesSlice';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { resetTransportation } from '../../redux/slices/transportationSlice';
import { resetAppointment } from '../../redux/slices/appointmentSlice';
import { VEHICLE_MILEAGE_LENGTH, VEHICLE_MILEAGE_MAXIMUM_VALUE, VEHICLE_VIN_LENGTH } from '../../constants/Validation';
import { areRequiredFieldsPopulated } from '../../util/Validation/RequiredFields';
import { verifyYear, verifyMake, verifyModel } from '../../util/Validation/Prefill';

interface IVehicleInput {
    make: string;
    mileage: string;
    model: string;
    vin: string;
    year: string;
}

const MenuItemPlaceholder = (
    <MenuItem value="" disabled>
        <FormattedMessage id="main.placeholder.select" />
    </MenuItem>
);

const errorAdornment = (
    <InputAdornment position="end">
        <ErrorIcon className={globalStyles['error-icon']} />
    </InputAdornment>
);

const Vehicle = (): JSX.Element => {
    const { app } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const vehicleState = useAppSelector((state) => state.vehicle);

    const schema = yup.object().shape({
        vin: yup
            .string()
            .trim()
            .test('vin-checksum', `${intl.formatMessage({ id: 'vehicle.error.vin' })}`, (value) => {
                return value === undefined || isValidVin(value as string);
            }),
        mileage: yup
            .number()
            .typeError(`${intl.formatMessage({ id: 'vehicle.error.integerValue' })}`)
            .max(VEHICLE_MILEAGE_MAXIMUM_VALUE, `${intl.formatMessage({ id: 'vehicle.error.maxValue' })}`)
            .positive(`${intl.formatMessage({ id: 'vehicle.error.positiveValue' })}`)
            .integer(`${intl.formatMessage({ id: 'vehicle.error.integerValue' })}`),
    });

    const getMakes = useCallback(
        (year: string) => {
            dispatch(
                fetchMakes({
                    country: app.country,
                    year,
                })
            );
        },
        [app.country, dispatch]
    );

    const getModels = useCallback(
        (year: string, make: string) => {
            dispatch(
                fetchModels({
                    country: app.country,
                    year,
                    make,
                })
            );
        },
        [app.country, dispatch]
    );

    useDeepCompareEffect(() => {
        if (
            app.iframeParams.year &&
            vehicleState.years?.year &&
            verifyYear(app.iframeParams.year, vehicleState.years.year) &&
            app.prefilledParams === false
        ) {
            getMakes(app.iframeParams.year);
        }

        if (
            app.iframeParams.make &&
            vehicleState.makes &&
            verifyMake(app.iframeParams.make, vehicleState.makes.gm, vehicleState.makes.other) &&
            app.prefilledParams === false
        ) {
            getModels(app.iframeParams.year, app.iframeParams.make);
        }

        if (
            app.iframeParams.model &&
            vehicleState.models &&
            verifyModel(app.iframeParams.model, vehicleState.models.model) &&
            app.prefilledParams === false
        ) {
            let iframeParams = {
                year: app.iframeParams.year,
                make: app.iframeParams.make,
                model: app.iframeParams.model,
                mileage: app.iframeParams.mileage,
                vin: app.iframeParams.vin,
            };
            onSubmit(iframeParams);
            dispatch(setPrefilledParams(true));
        }
    }, [
        app.iframeParams.make,
        app.iframeParams.year,
        app.iframeParams.model,
        app.iframeParams.mileage,
        app.iframeParams.vin,
        getMakes,
        getModels,
        vehicleState.makes,
        vehicleState.years,
        vehicleState.models,
    ]);

    const onSubmit = (values: IVehicleInput) => {
        batch(() => {
            dispatch(setMake(values.make));
            dispatch(setModel(values.model));
            dispatch(setYear(values.year));
            dispatch(resetServices());
            dispatch(resetTransportation());
            dispatch(resetAppointment());

            if (values.vin) {
                dispatch(setVin(values.vin));
            }

            if (values.mileage) {
                dispatch(setMileage(values.mileage));
            }

            // If units = true indicates mileage in miles, units = false km
            const units = app.country === 'US';

            dispatch(
                fetchServices({
                    bac: app.bac,
                    country: app.country,
                    locale: app.locale,
                    year: values.year,
                    make: values.make,
                    model: values.model,
                    plate: 'true',
                    vin: values.vin,
                    mileage: values.mileage || '0',
                    units: `${units}`,
                })
            );
            dispatch(setEnableServicesSection(true));
            dispatch(setIsValidVehicle(true));
        });
    };

    const handleBlur = (values: IVehicleInput) => {
        batch(() => {
            dispatch(setIsValidVehicle(false));
            dispatch(setAreValidServices(false));
            dispatch(resetServices());
            dispatch(setEnableServicesSection(false));
        });

        areRequiredFieldsPopulated(values, ['year', 'make', 'model']) && onSubmit(values);
    };

    const handleChangeYear = (
        event: ChangeEvent<HTMLInputElement>,
        handleChange: HandleFormikChange,
        setFieldValue: SetFormikFieldValue
    ) => {
        handleChange(event);
        setFieldValue('make', '');
        setFieldValue('model', '');

        app.enableServicesSection &&
            batch(() => {
                dispatch(setIsValidVehicle(false));
                dispatch(setAreValidServices(false));
                dispatch(resetServices());
                dispatch(setEnableServicesSection(false));
            });

        batch(() => {
            dispatch(setModel(''));
            dispatch(setMake(''));
            getMakes(event.target.value);
        });
    };

    const handleChangeMake = (
        event: ChangeEvent<HTMLInputElement>,
        handleChange: HandleFormikChange,
        setFieldValue: SetFormikFieldValue,
        values: IVehicleInput
    ) => {
        handleChange(event);
        setFieldValue('model', '');

        app.enableServicesSection &&
            batch(() => {
                dispatch(setIsValidVehicle(false));
                dispatch(setAreValidServices(false));
                dispatch(resetServices());
                dispatch(setEnableServicesSection(false));
            });

        batch(() => {
            dispatch(setModel(''));
            getModels(values.year, event.target.value);
        });
    };

    const initialValues = {
        make: app.iframeParams.make || '',
        model: app.iframeParams.model || '',
        year: app.iframeParams.year || '',
        mileage: app.iframeParams.mileage || '',
        vin: app.iframeParams.vin || '',
    };

    return (
        <Formik
            initialValues={initialValues}
            initialTouched={{
                mileage: app.iframeParams.mileage ? true : false,
                vin: app.iframeParams.vin ? true : false,
            }}
            enableReinitialize
            validationSchema={schema}
            validateOnMount
            onSubmit={(values: IVehicleInput) => {
                onSubmit(values);
            }}
        >
            {({ errors, handleChange, handleSubmit, setFieldValue, touched, values }) => (
                <Form className={styles['vehicle']} data-testid="vehicle">
                    <Grid container spacing={3} alignItems="baseline">
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                <FormattedMessage id="vehicle.header" />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                <InputLabel
                                    className="bold"
                                    error={touched.year && !values.year}
                                    htmlFor="year-select"
                                    shrink
                                >
                                    <FormattedMessage id="vehicle.label.year" />
                                </InputLabel>
                                <Field
                                    component={Select}
                                    disabled={!vehicleState.years || app.submissionSuccessful}
                                    displayEmpty
                                    error={touched.year && !values.year}
                                    fullWidth
                                    inputProps={{
                                        id: 'year-select',
                                        'data-testid': 'year-select',
                                    }}
                                    MenuProps={MENU_PROPS}
                                    name="year"
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        handleChangeYear(event, handleChange, setFieldValue);
                                    }}
                                    variant="outlined"
                                >
                                    {MenuItemPlaceholder}
                                    {vehicleState.years?.year?.map((item) => (
                                        <MenuItem key={item} value={item} id={item.toString()}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText error={touched.year && !values.year}>
                                    {touched.year && !values.year ? <FormattedMessage id="vehicle.error.year" /> : null}
                                </FormHelperText>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                <InputLabel
                                    className="bold"
                                    error={touched.make && !values.make}
                                    htmlFor="make-select"
                                    shrink
                                >
                                    <FormattedMessage id="vehicle.label.make" />
                                </InputLabel>
                                <Field
                                    component={Select}
                                    disabled={!values.year || app.submissionSuccessful || !vehicleState.makes}
                                    displayEmpty
                                    error={touched.make && !values.make}
                                    fullWidth
                                    inputProps={{
                                        id: 'make-select',
                                        'data-testid': 'make-select',
                                    }}
                                    MenuProps={MENU_PROPS}
                                    name="make"
                                    value={values.make}
                                    variant="outlined"
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        handleChangeMake(event, handleChange, setFieldValue, values);
                                    }}
                                >
                                    {MenuItemPlaceholder}
                                    {vehicleState.makes?.gm.length ? (
                                        <MenuItem disabled>
                                            <Typography variant="h5">
                                                <FormattedMessage id="vehicle.label.gm" />
                                            </Typography>
                                        </MenuItem>
                                    ) : null}
                                    {vehicleState.makes?.gm?.map((item, index) => (
                                        <MenuItem
                                            className={'vehicle-menu-item-make'}
                                            key={index}
                                            value={item.make.toUpperCase()}
                                        >
                                            {item.make}
                                        </MenuItem>
                                    ))}
                                    {vehicleState.makes?.other.length ? (
                                        <MenuItem disabled>
                                            <Typography variant="h5">
                                                <FormattedMessage id="vehicle.label.other" />
                                            </Typography>
                                        </MenuItem>
                                    ) : null}
                                    {vehicleState.makes?.other?.map((item, index) => (
                                        <MenuItem
                                            className={'vehicle-menu-item-make'}
                                            key={index}
                                            value={item.make.toUpperCase()}
                                        >
                                            {item.make}
                                        </MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText error={touched.make && !values.make}>
                                    {touched.make && !values.make ? <FormattedMessage id="vehicle.error.make" /> : null}
                                </FormHelperText>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                <InputLabel
                                    className="bold"
                                    error={touched.model && !values.model}
                                    htmlFor="model-select"
                                    shrink
                                >
                                    <FormattedMessage id="vehicle.label.model" />
                                </InputLabel>
                                <Field
                                    component={Select}
                                    disabled={!values.make || app.submissionSuccessful || !vehicleState.models}
                                    displayEmpty
                                    error={touched.model && !values.model}
                                    fullWidth
                                    inputProps={{
                                        id: 'model-select',
                                        'data-testid': 'model-select',
                                    }}
                                    MenuProps={MENU_PROPS}
                                    name="model"
                                    variant="outlined"
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('model', event.target.value);
                                        handleSubmit();
                                    }}
                                >
                                    {MenuItemPlaceholder}
                                    {vehicleState.models?.model?.map((item, index) => (
                                        <MenuItem key={index} value={item.toUpperCase()}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Field>
                                <FormHelperText error={touched.model && !values.model}>
                                    {touched.model && !values.model ? (
                                        <FormattedMessage id="vehicle.error.model" />
                                    ) : null}
                                </FormHelperText>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={TextField}
                                disabled={app.submissionSuccessful}
                                fullWidth
                                inputProps={{
                                    id: 'mileage-field',
                                    'data-testid': 'mileage-field',
                                    maxLength: VEHICLE_MILEAGE_LENGTH,
                                }}
                                InputProps={{
                                    endAdornment: touched.mileage && errors.mileage ? errorAdornment : null,
                                }}
                                label={
                                    <>
                                        <FormattedMessage id="vehicle.label.mileage" /> {` `}
                                        <FormattedMessage id="main.label.optional" />
                                    </>
                                }
                                name="mileage"
                                type="text"
                                onBlur={() => handleBlur(values)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={TextField}
                                disabled={app.submissionSuccessful}
                                fullWidth
                                inputProps={{
                                    id: 'vin-field',
                                    'data-testid': 'vin-field',
                                    maxLength: VEHICLE_VIN_LENGTH,
                                }}
                                InputProps={{
                                    endAdornment: touched.vin && errors.vin ? errorAdornment : null,
                                }}
                                label={
                                    <>
                                        <FormattedMessage id="vehicle.label.vin" />
                                        {` `}
                                        <FormattedMessage id="main.label.optional" />
                                    </>
                                }
                                name="vin"
                                type="text"
                                onBlur={() => handleBlur(values)}
                            />
                        </Grid>
                        {app.mobileServicePlusEnabled && (
                            <Grid item xs={12}>
                                <Typography>
                                    <FormattedMessage id="vehicle.vin.dealer.enrolled" />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default Vehicle;
