// Constants for StandardizeServices.ts
export const DEALER_CONFIG_ADDITIONAL = 'DEALER_CONFIG_ADDITIONAL';
export const DEALER_CONFIG_RECOMMENDED = 'DEALER_CONFIG_RECOMMENDED';
export const DEALERCONFIG = 'DEALERCONFIG';
export const IVH = 'IVH';
export const MAINTENANCE_SCHEDULE = 'MAINTENANCE  SCHEDULE';
export const NONCRITICAL = 'NONCRITICAL';
export const PEGA_RECALL = 'PEGA_RECALL';
export const PRIORITY = 'PRIORITY';
export const OFFER = 'OFFER';
export const IVH_ORDER = 1;
export const PRIORITY_ORDER = 2;
export const NONCRITICAL_ORDER = 3;
export const DEALER_CONFIG_RECOMMENDED_ORDER = 4;
export const DEALER_CONFIG_ADDITIONAL_ORDER = 5;
