import { Slide, Snackbar } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useIntl } from 'react-intl';

const GlobalAlert = (): JSX.Element => {
    const { alert } = useAppSelector((state) => state.alert);
    const [open, setOpen] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if (alert.message) {
            setOpen(true);
        }
    }, [alert]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                TransitionComponent={Slide}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={alert.severity as Color}>
                    {alert.message && intl.formatMessage({ id: alert.message })}
                </Alert>
            </Snackbar>
        </>
    );
};

export default GlobalAlert;
