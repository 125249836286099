// All
export const NOTES_LENGTH = 300;

// Customer
export const CUSTOMER_FIRST_NAME_LENGTH = 20;
export const CUSTOMER_LAST_NAME_LENGTH = 30;
export const CUSTOMER_NAME_REGEX = /^[A-Za-z.-]*$/;
export const CUSTOMER_PHONE_NUMBER_LENGTH = 14;
export const CUSTOMER_EMAIL_LENGTH = 58;

// Vehicle
export const VEHICLE_MILEAGE_MAXIMUM_VALUE = 999999999;
export const VEHICLE_MILEAGE_LENGTH = 9;
export const VEHICLE_VIN_LENGTH = 17;

// Services
export const SERVICES_SEARCH_LENGTH = 100;

// Appointment scheduling
export const APPOINTMENT_MAX_LOOKAHEAD_DAYS = 180;
export const APPOINTMENT_DATE_FORMAT = 'YYYY-MM-DD';

//Address
export const ADDRESS_LENGTH = 70;
export const ADDRESS_CITY_LENGTH = 30;
export const ADDRESS_REGEX = /^[a-zA-Z0-9.\s-]*$/;
export const ADDRESS_ZIP_LENGTH = 10;
export const ADDRESS_ZIP_REGEX = /^[0-9-]*$/;
export const ADDRESS_STATE_LENGTH = 58;
