import { Color } from '@material-ui/lab';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    fetchAvailability,
    fetchAvailabilityDms,
    fetchCountryOptions,
    fetchMakes,
    fetchModels,
    fetchNextAvailability,
    fetchServices,
    fetchSummary,
    fetchTransportationOptions,
    fetchYears,
    postAppointment,
} from '../asyncThunkApiMethods';

type AlertType = { severity: Color; message: string; type: string };
type DefaultAlertState = {
    alert: AlertType;
};

const initialState: DefaultAlertState = {
    alert: {} as AlertType,
};

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        addAlert: (state, { payload }: PayloadAction<AlertType>) => {
            state.alert = payload;
        },
        setTimeslot: (state, { payload }: PayloadAction<string>) => {
            state.alert.type = payload;
        },
        resetAlert: (state) => {
            state.alert = {} as AlertType;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAvailability.rejected, (state, { payload }) => {
            if (payload) {
                // @ts-ignore
                if (payload.message.includes('location is outside')) {
                    // @ts-ignore
                    state.alert = { severity: 'error', message: payload.message, type: 'onTrac' };
                } else {
                    // @ts-ignore
                    state.alert = { severity: 'error', message: payload.message, type: '' };
                }
            }
        });
        builder.addCase(fetchAvailabilityDms.rejected, (state, { payload }) => {
            if (payload) {
                // @ts-ignore
                if (payload.message.includes('location is outside')) {
                    // @ts-ignore
                    state.alert = { severity: 'error', message: payload.message, type: 'onTrac' };
                } else {
                    // @ts-ignore
                    state.alert = { severity: 'error', message: payload.message, type: '' };
                }
            }
        });
        builder.addCase(fetchCountryOptions.rejected, (state, { payload }) => {
            if (payload) {
                state.alert = { severity: 'error', message: payload.message, type: '' };
            }
        });
        builder.addCase(fetchSummary.rejected, (state, { payload }) => {
            if (payload) {
                state.alert = { severity: 'error', message: payload.message, type: '' };
            }
        });
        builder.addCase(fetchMakes.rejected, (state, { payload }) => {
            if (payload) {
                state.alert = { severity: 'error', message: payload.message, type: '' };
            }
        });
        builder.addCase(fetchModels.rejected, (state, { payload }) => {
            if (payload) {
                state.alert = { severity: 'error', message: payload.message, type: '' };
            }
        });
        builder.addCase(fetchNextAvailability.rejected, (state, { payload }) => {
            if (payload) {
                // @ts-ignore
                if (payload.message.includes('location is outside')) {
                    // @ts-ignore
                    state.alert = { severity: 'error', message: payload.message, type: 'onTrac' };
                } else {
                    // @ts-ignore
                    state.alert = { severity: 'error', message: payload.message, type: '' };
                }
            }
        });
        builder.addCase(fetchServices.rejected, (state, { payload }) => {
            if (payload) {
                state.alert = { severity: 'error', message: payload.message, type: '' };
            }
        });
        builder.addCase(fetchTransportationOptions.rejected, (state, { payload }) => {
            if (payload) {
                state.alert = { severity: 'error', message: payload.message, type: '' };
            }
        });
        builder.addCase(fetchYears.rejected, (state, { payload }) => {
            if (payload) {
                state.alert = { severity: 'error', message: payload.message, type: '' };
            }
        });
        builder.addCase(postAppointment.fulfilled, (state) => {
            state.alert = { severity: 'success', message: 'alert.submitted', type: '' };
        });
        builder.addCase(postAppointment.rejected, (state, { payload }) => {
            if (payload) {
                if (payload.message.includes('Date/time not available.')) {
                    state.alert = { severity: 'error', message: payload.message, type: 'timeslot' };
                } else if (payload.message.includes('Unable to assign van to appointment:')) {
                    state.alert = { severity: 'error', message: payload.message, type: 'van' };
                } else {
                    state.alert = { severity: 'error', message: payload.message, type: '' };
                }
            }
        });
    },
});

export const AlertReducer = alertSlice.reducer;
export const { addAlert, setTimeslot, resetAlert } = alertSlice.actions;
