import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { CountryOptions } from '../../types/CountryOptions.type';
import { IframeParams } from '../../types/IframeParams.type';
import { setupDateLocalization } from '../../util/DateLocalization';
import { fetchCountryOptions, fetchSummary, postAppointment } from '../asyncThunkApiMethods';

type DefaultAppState = {
    bac: string;
    country: string;
    countryOptions: CountryOptions;
    enableServicesSection: boolean;
    error: boolean;
    iframeParams: IframeParams;
    isSubmittingAppointment: boolean;
    isValidInput: boolean;
    loading: boolean;
    locale: string;
    submissionSuccessful: boolean;
    useDmsAvailability: boolean;
    prefilledParams: boolean;
    mobileServicePlusEnabled: boolean;
    dealerAddress: string;
};

const initialState: DefaultAppState = {
    bac: '', // TODO: Use only for dev work
    country: '',
    countryOptions: {} as CountryOptions,
    enableServicesSection: false,
    error: false,
    iframeParams: {} as IframeParams,
    isSubmittingAppointment: false,
    isValidInput: false,
    loading: false,
    locale: 'en-US',
    submissionSuccessful: false,
    useDmsAvailability: true,
    prefilledParams: false,
    mobileServicePlusEnabled: false,
    dealerAddress: ''
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setBAC: (state, { payload }: PayloadAction<string>) => {
            state.bac = payload;
        },
        setParams: (state, { payload }: PayloadAction<string>) => {
            const query = queryString.parse(payload);

            if (query.bac && query.country && query.locale) {
                state.bac = query.bac as string;
                state.country = query.country as string;
                state.locale = query.locale as string;
                state.iframeParams = {
                    year: query.year ? (query.year as string) : '',
                    make: query.make ? (query.make as string).toUpperCase() : '',
                    model: query.model ? (query.model as string).toUpperCase() : '',
                    vin: query.vin ? (query.vin as string) : '',
                    mileage: query.mileage ? (query.mileage as string) : '',
                    firstName: query.firstname ? (query.firstname as string) : '',
                    lastName: query.lastname ? (query.lastname as string) : '',
                    contact: query.contact ? (query.contact as string) : '',
                    phone: query.phone ? (query.phone as string) : '',
                    email: query.email ? (query.email as string) : '',
                };

                setupDateLocalization(query.locale as string);
            } else {
                state.error = true;
            }
        },
        setEnableServicesSection: (state, { payload }: PayloadAction<boolean>) => {
            state.enableServicesSection = payload;
        },
        setPrefilledParams: (state, { payload }: PayloadAction<boolean>) => {
            state.prefilledParams = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCountryOptions.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.countryOptions = payload;
            state.country = payload.defaultLocale.country;
            state.locale = payload.defaultLocale.tag;
        });
        builder.addCase(fetchSummary.fulfilled, (state, { payload }) => {
            state.loading = false;
            const { dmsConfiguration } = payload.dealershipSummary;

            if (dmsConfiguration) {
                state.useDmsAvailability = dmsConfiguration.useDmsAvailability;
            } else {
                state.useDmsAvailability = false;
            }
            state.mobileServicePlusEnabled = payload.mobileServicePlusEnabled;
            const dealerAddressObject = payload.dealershipSummary?.address;
            state.dealerAddress = dealerAddressObject ? [dealerAddressObject.addressLine1, dealerAddressObject.cityName, dealerAddressObject.countrySubentity, dealerAddressObject.country]
                .filter(Boolean)
                .join(", ") : "";
        });
        builder.addCase(postAppointment.pending, (state) => {
            state.isSubmittingAppointment = true;
        });
        builder.addCase(postAppointment.fulfilled, (state, { payload }) => {
            state.isSubmittingAppointment = false;
            state.submissionSuccessful = true;
        });
        builder.addCase(postAppointment.rejected, (state) => {
            state.isSubmittingAppointment = false;
        });
    },
});

export const AppReducer = appSlice.reducer;
export const { setBAC, setParams, setEnableServicesSection, setPrefilledParams } = appSlice.actions;
