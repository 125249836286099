import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducer from './slices/rootReducer';

const AppStore = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(thunk),
});

export type RootState = ReturnType<typeof AppStore.getState>;
export type AppDispatch = typeof AppStore.dispatch;

export default AppStore;
