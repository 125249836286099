import { Grid, Typography, Box, InputLabel, MenuItem, InputAdornment } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field, Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import {
    setFirstName,
    setLastName,
    setEmail,
    setIsValidCustomer,
    setPreferredContact,
    setPhoneNumber,
} from '../../redux/slices/customerSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { CountryCode, isValidNumberForRegion } from 'libphonenumber-js';
import { batch } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import globalStyles from '../App.module.scss';
import {
    CUSTOMER_FIRST_NAME_LENGTH,
    CUSTOMER_LAST_NAME_LENGTH,
    CUSTOMER_NAME_REGEX,
    CUSTOMER_PHONE_NUMBER_LENGTH,
    CUSTOMER_EMAIL_LENGTH,
} from '../../constants/Validation';
import { areRequiredFieldsPopulated } from '../../util/Validation/RequiredFields';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface ICustomerInput {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    preferredContact: string;
}

const errorAdornment = (
    <InputAdornment position="end">
        <ErrorIcon className={globalStyles['error-icon']} />
    </InputAdornment>
);

const Customer = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const app = useAppSelector((state) => state.app);
    const intl = useIntl();

    const initialValues = {
        firstName: app.iframeParams.firstName || '',
        lastName: app.iframeParams.lastName || '',
        email: app.iframeParams.email || '',
        preferredContact: app.iframeParams.contact ? app.iframeParams.contact.substring(0, 5).toUpperCase() : 'EMAIL',
        phoneNumber: app.iframeParams.phone || '',
    };

    const customerSchema = Yup.object({
        firstName: Yup.string()
            .trim()
            .max(CUSTOMER_FIRST_NAME_LENGTH)
            .required(`${intl.formatMessage({ id: 'customer.error.firstName' })}`)
            .matches(CUSTOMER_NAME_REGEX, 'Please enter valid name'),
        lastName: Yup.string()
            .trim()
            .max(CUSTOMER_LAST_NAME_LENGTH)
            .required(`${intl.formatMessage({ id: 'customer.error.lastName' })}`)
            .matches(CUSTOMER_NAME_REGEX, 'Please enter valid name'),
        email: Yup.string()
            .trim()
            .max(CUSTOMER_EMAIL_LENGTH)
            .email(`${intl.formatMessage({ id: 'customer.error.emailInvalid' })}`)
            .required(`${intl.formatMessage({ id: 'customer.error.emailRequired' })}`),
        phoneNumber: Yup.string()
            .trim()
            .max(CUSTOMER_PHONE_NUMBER_LENGTH)
            .required(`${intl.formatMessage({ id: 'customer.error.phoneNumberRequired' })}`)
            .test('PNTest', `${intl.formatMessage({ id: 'customer.error.phoneNumberInvalid' })}`, (value) => {
                if (value) {
                    console.log(app.country + '-' + app.country as CountryCode);
                    return isValidNumberForRegion(String(value), app.country as CountryCode);
                } else {
                    return true;
                }
            }),
        preferredContact: Yup.string().required(`${intl.formatMessage({ id: 'customer.error.preferredContact' })}`),
    }); //this is really just a fall back for if countryOptions never populates

    let customerSchema1;

    if (Object.keys(app.countryOptions).length > 0) {
        //if countryOptions has been populated;
        customerSchema1 = Yup.object().shape({
            phoneIsRequired: Yup.boolean(),
            firstName: Yup.string()
                .trim()
                .max(CUSTOMER_FIRST_NAME_LENGTH)
                .required(`${intl.formatMessage({ id: 'customer.error.firstName' })}`)
                .matches(CUSTOMER_NAME_REGEX, 'Please enter valid name'),
            lastName: Yup.string()
                .trim()
                .max(CUSTOMER_LAST_NAME_LENGTH)
                .required(`${intl.formatMessage({ id: 'customer.error.lastName' })}`)
                .matches(CUSTOMER_NAME_REGEX, 'Please enter valid name'),
            email: Yup.string()
                .trim()
                .max(CUSTOMER_EMAIL_LENGTH)
                .email(`${intl.formatMessage({ id: 'customer.error.emailInvalid' })}`)
                .test('emailTest', `${intl.formatMessage({ id: 'customer.error.emailRequired' })}`, (value) => {
                    if (!value) {
                        return app.countryOptions.countryOptions.REQUIRE_EMAIL === 'false';
                    } else {
                        return true;
                    }
                }),
            phoneNumber: Yup.string()
                .trim()
                .max(CUSTOMER_PHONE_NUMBER_LENGTH)
                .test('PNValidTest', `${intl.formatMessage({ id: 'customer.error.phoneNumberInvalid' })}`, (value) => {
                    if (!value) {
                        return true;
                    } else {
                        console.log(app.countryOptions.defaultLocale.country + '-' + app.countryOptions.defaultLocale.country as CountryCode);
                        return isValidNumberForRegion(
                            String(value),
                            app.countryOptions.defaultLocale.country as CountryCode
                        );
                    }
                })
                .test(
                    'PNRequireTest',
                    `${intl.formatMessage({ id: 'customer.error.phoneNumberRequired' })}`,
                    (value) => {
                        if (!value) {
                            return app.countryOptions.countryOptions.REQUIRE_PHONE === 'false';
                        } else {
                            return true;
                        }
                    }
                ),
            preferredContact: Yup.string().required(`${intl.formatMessage({ id: 'customer.error.preferredContact' })}`),
        });
    }

    useDeepCompareEffect(() => {
        areRequiredFieldsPopulated(initialValues) && onSubmit(initialValues);
        console.log('re-rendering');
    }, [initialValues]);

    const onSubmit = (values: ICustomerInput) => {
        batch(() => {
            dispatch(setFirstName(values.firstName as string));
            dispatch(setLastName(values.lastName as string));
            dispatch(setEmail(values.email as string));
            dispatch(setPhoneNumber(values.phoneNumber as string));
            dispatch(setPreferredContact(values.preferredContact as string));
            dispatch(setIsValidCustomer(true));
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            initialTouched={{
                firstName: app.iframeParams.firstName ? true : false,
                lastName: app.iframeParams.lastName ? true : false,
                email: app.iframeParams.email ? true : false,
                phoneNumber: app.iframeParams.phone ? true : false,
            }}
            enableReinitialize={true}
            validationSchema={Object.keys(app.countryOptions).length > 0 ? customerSchema1 : customerSchema}
            validateOnMount={true}
            onSubmit={(values: ICustomerInput, { setSubmitting }) => {
                setSubmitting(false);
                onSubmit(values);
            }}
        >
            {({ errors, handleSubmit, touched, values }) => (
                <Form
                    data-testid="customer"
                    onBlur={() => {
                        dispatch(setIsValidCustomer(false));
                        areRequiredFieldsPopulated(values) && handleSubmit();
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                <FormattedMessage id="customer.header" />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                disabled={app.submissionSuccessful}
                                component={TextField}
                                fullWidth
                                inputProps={{
                                    id: 'first-name-field',
                                    'data-testid': 'first-name-field',
                                    maxLength: CUSTOMER_FIRST_NAME_LENGTH,
                                }}
                                InputProps={{
                                    endAdornment: touched.firstName && errors.firstName ? errorAdornment : null,
                                }}
                                name="firstName"
                                label={<FormattedMessage id="customer.label.firstName" />}
                                required
                            ></Field>
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                disabled={app.submissionSuccessful}
                                fullWidth
                                name="lastName"
                                data-testid="last-name"
                                inputProps={{
                                    id: 'last-name-field',
                                    'data-testid': 'last-name-field',
                                    maxLength: CUSTOMER_LAST_NAME_LENGTH,
                                }}
                                InputProps={{
                                    endAdornment: touched.lastName && errors.lastName ? errorAdornment : null,
                                }}
                                label={<FormattedMessage id="customer.label.lastName" />}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                disabled={app.submissionSuccessful}
                                inputProps={{
                                    id: 'email-field',
                                    'data-testid': 'email-field',
                                    maxLength: CUSTOMER_EMAIL_LENGTH,
                                }}
                                InputProps={{
                                    endAdornment: touched.email && errors.email ? errorAdornment : null,
                                }}
                                fullWidth
                                name="email"
                                label={<FormattedMessage id="customer.label.email" />}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                <InputLabel
                                    shrink
                                    id="preferred-contact-label"
                                    className="bold"
                                    error={Boolean(errors.preferredContact && touched.preferredContact)}
                                >
                                    <FormattedMessage id="customer.label.preferredContact" />
                                </InputLabel>
                                <Field
                                    component={TextField}
                                    disabled={app.submissionSuccessful}
                                    type="text"
                                    select={true}
                                    fullWidth
                                    label-id="preferred-contact-label"
                                    id="preferred-contact-select"
                                    variant="outlined"
                                    name="preferredContact"
                                    inputProps={{
                                        id: 'preferred-contact-select',
                                        'data-testid': 'preferred-contact-select',
                                    }}
                                >
                                    <MenuItem value="PHONE">
                                        <FormattedMessage id="customer.label.phoneNumber" />
                                    </MenuItem>
                                    <MenuItem value="EMAIL">
                                        <FormattedMessage id="customer.label.email" />
                                    </MenuItem>
                                </Field>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                disabled={app.submissionSuccessful}
                                fullWidth
                                name="phoneNumber"
                                inputProps={{
                                    id: 'phone-number-field',
                                    'data-testid': 'phone-number-field',
                                    maxLength: CUSTOMER_PHONE_NUMBER_LENGTH,
                                }}
                                InputProps={{
                                    endAdornment: touched.phoneNumber && errors.phoneNumber ? errorAdornment : null,
                                }}
                                label={<FormattedMessage id="customer.label.phoneNumber" />}
                                required
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default Customer;
