import RegexParser from "regex-parser";

interface AddressObject {
    type: string;
    addressLine1: string;
    cityName: string;
    countrySubentity: string;
    countrySubentityCode: string;
    postalZone: string;
}

export const buildAddressArray = (value: any) => {
    let addressArray = [];
    let pickupAddress: AddressObject = {
        type: 'PICK_UP',
        addressLine1: value.pickupAddress1 + ' ' + value.pickupAddress2,
        cityName: value.pickupCity,
        countrySubentity: value.pickupState,
        countrySubentityCode: 'US-' + value.pickupState,
        postalZone: value.pickupZip,
    };

    addressArray.push(pickupAddress);

    if (value.differentDropoff) {
        let dropoffAddress: AddressObject = {
            type: 'DROP_OFF',
            addressLine1: value.dropoffAddress1 + ' ' + value.dropoffAddress2,
            cityName: value.dropoffCity,
            countrySubentity: value.dropoffState,
            countrySubentityCode: 'US-' + value.dropoffState,
            postalZone: value.dropoffZip,
        };
        addressArray.push(dropoffAddress);
    } else {
        let dropoffAddress: AddressObject = {
            type: 'DROP_OFF',
            addressLine1: value.pickupAddress1 + ' ' + value.pickupAddress2,
            cityName: value.pickupCity,
            countrySubentity: value.pickupState,
            countrySubentityCode: 'US-' + value.pickupState,
            postalZone: value.pickupZip,
        };
        addressArray.push(dropoffAddress);
    }
    return addressArray;
};

export const isValidAddress = (addressData: any, countryOptions: any) => {
    const noSpecialChars = RegexParser(countryOptions?.ADDRESS_REGEX_CFUI);
    const cityNameRegex = RegexParser(countryOptions?.CITY_NAME_REGEX);
    const postCodeRegex = RegexParser(countryOptions?.POSTCODE_REGEX);
    if (!addressData || !addressData.addressLine1 || !addressData.cityName || !addressData.countrySubentity || !addressData.postalZone) {
        return false;
    }
    return addressData.addressLine1.length > 5 && !addressData.addressLine1.match(noSpecialChars)
        && addressData.cityName.length > 0 && !addressData.cityName.match(noSpecialChars) && cityNameRegex.test(addressData.postalZone)
        && addressData.countrySubentity.length === 2 && !addressData.countrySubentity.match(noSpecialChars)
        && addressData.postalZone.length >= 5 && !addressData.postalZone.match(noSpecialChars) && postCodeRegex.test(addressData.postalZone);
};

