import _ from 'lodash';

const transliterationMap = [
    { char: 'a', weight: 1 },
    { char: 'b', weight: 2 },
    { char: 'c', weight: 3 },
    { char: 'd', weight: 4 },
    { char: 'e', weight: 5 },
    { char: 'f', weight: 6 },
    { char: 'g', weight: 7 },
    { char: 'h', weight: 8 },
    { char: 'j', weight: 1 },
    { char: 'k', weight: 2 },
    { char: 'l', weight: 3 },
    { char: 'm', weight: 4 },
    { char: 'n', weight: 5 },
    { char: 'p', weight: 7 },
    { char: 'r', weight: 9 },
    { char: 's', weight: 2 },
    { char: 't', weight: 3 },
    { char: 'u', weight: 4 },
    { char: 'v', weight: 5 },
    { char: 'w', weight: 6 },
    { char: 'x', weight: 7 },
    { char: 'y', weight: 8 },
    { char: 'z', weight: 9 },
];

const vinPositionWeights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

const transliterateVin = (vin: string) => {
    let vinArray = vin.split('');
    let transliteratedVin = [] as number[];
    let weightedSum = 0;
    vinArray.forEach((char, index) => {
        let weight: number = 0;
        if (/[a-hj-npr-z]/.test(char)) {
            const map = _.find(transliterationMap, { char: char });

            if (map) {
                weight = map.weight;
                weightedSum += map.weight * vinPositionWeights[index];
            }
        } else {
            weight = +char;
            weightedSum += +char * vinPositionWeights[index];
        }
        transliteratedVin.push(weight);
    });

    return weightedSum;
};

const validateChecksum = (checkDigit: string, weightedSum: number) => {
    let isValid;
    let checksum = weightedSum % 11;

    // Check digit is 0-9
    if (checksum.toString() === checkDigit) {
        isValid = true;
        // Check digit is 10
    } else if (checkDigit === 'x' && checksum === 10) {
        isValid = true;
    } else {
        isValid = false;
    }

    return isValid;
};

export const isValidVin = (vin: string): boolean => {
    let input = vin.toLowerCase().trim();
    let isValid;
    const hasInvalidChars = /[^a-hj-npr-z0-9]/.test(input);

    if (input.length === 17 && !hasInvalidChars) {
        const checkDigit = input[8];
        let weightedSum = transliterateVin(input);
        isValid = validateChecksum(checkDigit, weightedSum);
    } else {
        isValid = false;
    }

    return isValid;
};
