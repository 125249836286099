import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LineItem } from '../../types/Appointment.type';
import { standardizeServices } from '../../util/StandardizeServices';
import { fetchServices } from '../asyncThunkApiMethods';

type DefaultServicesState = {
    isValid: boolean;
    loading: boolean;
    selectedServices: LineItem[];
    serviceArray: LineItem[];
    serviceComments?: string;
};

const initialState: DefaultServicesState = {
    isValid: false,
    loading: false,
    selectedServices: [] as LineItem[],
    serviceArray: [
        {
            code: '0',
        } as LineItem,
    ],
    serviceComments: '',
};

const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setSelectedServices: (state, { payload }: PayloadAction<LineItem[]>) => {
            state.selectedServices = payload;
        },
        setServiceArray: (state, { payload }: PayloadAction<LineItem[]>) => {
            state.serviceArray = payload;
        },
        setServiceComments: (state, { payload }: PayloadAction<string>) => {
            state.serviceComments = payload;
        },
        resetServices: (state) => {
            state.serviceArray = [
                {
                    code: '0',
                } as LineItem,
            ];
            state.selectedServices = [] as LineItem[];
            state.serviceComments = '';
        },
        setAreValidServices: (state, { payload }: PayloadAction<boolean>) => {
            state.isValid = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchServices.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchServices.fulfilled, (state, { payload }) => {
            state.loading = false;
            const standardizedServices = standardizeServices(payload);

            state.serviceArray = standardizedServices;
            state.selectedServices = [] as LineItem[];
            state.serviceComments = '';
        });
        builder.addCase(fetchServices.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const ServicesReducer = servicesSlice.reducer;
export const { resetServices, setServiceComments, setAreValidServices, setSelectedServices, setServiceArray } =
    servicesSlice.actions;
