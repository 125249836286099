import { Appointment, LineItem } from '../types/Appointment.type';

const PopulateAppointmentObject = (
    state: any,
    selectedService?: any,
    transportOption?: any,
    pickupAddress?: any,
    dropoffAddress?: any,
    serviceAddress?: any
): Appointment => {
    const { app, appointment, customer, services, transportation, vehicle } = state;
    const cleanedLineItems = selectedService
        ? cleanLineItems(selectedService)
        : cleanLineItems(services.selectedServices);

    const newTransportOption = transportOption ? transportOption : transportation.transport;

    const newPickupAddress = pickupAddress ? pickupAddress : appointment.pickupAddress;
    const newDropoffAddress = dropoffAddress ? dropoffAddress : appointment.dropoffAddress;
    const newServiceAddress = serviceAddress ? serviceAddress : appointment.serviceAddresses;
    return {
        customer: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            preferPhone: customer.preferredContact === 'PHONE',
            communications: [
                {
                    type: 'EMAIL',
                    value: customer.email,
                },
                {
                    type: 'PHONE',
                    value: customer.phoneNumber,
                },
            ],
        },
        vehicle: {
            mileage: vehicle.mileage ? vehicle.mileage : '0',
            vin: vehicle.vin || null,
            vehicle: {
                year: vehicle.year,
                make: {
                    make: vehicle.make,
                },
                model: {
                    model: vehicle.model,
                },
            },
        },
        bac: app.bac,
        customerComments: appointment.customerComments,
        customerLocale: app.locale,
        serviceNotes: services.serviceComments,
        appointmentTime: {
            start: appointment.appointmentTime || null,
        },
        appointmentDate: appointment.appointmentDate || null,
        countryCode: app.country,
        transportationOption: newTransportOption,
        origin: 'CC-Agent',
        lineItems: cleanedLineItems,
        dropoffLocation: newDropoffAddress || undefined,
        pickupLocation: newPickupAddress || undefined,
        serviceAddresses: newServiceAddress || undefined,
    };
};

const cleanLineItems = (dirtyLineItems: LineItem[]): LineItem[] => {
    let cleanLineItems: LineItem[] = [];

    cleanLineItems = dirtyLineItems.map((element) => {
        return {
            code: element.code,
            description: element.description,
            globalLaborCode: element.globalLaborCode,
            name: element.name,
            namePrefix: element.namePrefix,
            type: element.type,
            typePlus: element.typePlus,
        };
    });

    return cleanLineItems;
};

export default PopulateAppointmentObject;
