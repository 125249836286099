import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Availability, DateAvailability } from '../../types/Availability.type';
import { fetchAvailability, fetchAvailabilityDms, fetchNextAvailability } from '../asyncThunkApiMethods';

type DefaultAppointmentState = {
    appointmentDate: string;
    appointmentTime: string;
    availability: DateAvailability[];
    customerComments?: string;
    canSubmitAddress: boolean;
    isValid: boolean;
    loading: boolean;
    maxLookaheadDate: string;
    nextDate: string;
    nextTime: string;
    timeSlots: string[];
    unavailableDates: string[];
    availabilityGuid?: string;
    dropoffAddress: string;
    pickupAddress: string;
    serviceAddresses?: [
        {
            type: string;
            addressLine1: string;
            cityName: string;
            countrySubentity: string;
            countrySubentityCode: string;
            postalZone: string;
        }
    ];
};

const initialState: DefaultAppointmentState = {
    appointmentDate: '',
    appointmentTime: '',
    availability: [] as DateAvailability[],
    isValid: false,
    canSubmitAddress: true,
    loading: false,
    maxLookaheadDate: '',
    nextDate: '',
    nextTime: '',
    timeSlots: [],
    unavailableDates: [],
    availabilityGuid: '',
    dropoffAddress: '',
    pickupAddress: '',
    serviceAddresses: [
        {
            type: '',
            addressLine1: '',
            cityName: '',
            countrySubentity: '',
            countrySubentityCode: '',
            postalZone: '',
        },
    ],
};

const convertAvailabilityToDates = (
    availability: Availability
): { unavailableDates: string[]; availableDates: DateAvailability[] } => {
    const availableDates = _.union(
        availability.appointmentAvailabilities.DEFAULT,
        availability.appointmentAvailabilities.EXPRESS_SERVICE
    );

    let unavailableDates: string[] = [];

    availableDates.forEach((date) => {
        if (!date.time.length) {
            unavailableDates.push(dayjs(date.date).format());
        }
    });

    return { availableDates, unavailableDates };
};

const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        resetAppointment: (state) => {
            state.appointmentDate = state.nextDate;
            state.appointmentTime = state.nextTime;
        },
        setAppointmentDate: (state, { payload }: PayloadAction<string>) => {
            state.appointmentDate = payload;
        },
        setAppointmentTime: (state, { payload }: PayloadAction<string>) => {
            state.appointmentTime = payload;
        },

        setNextDate: (state, { payload }: PayloadAction<string>) => {
            state.nextDate = payload;
        },
        setNextTime: (state, { payload }: PayloadAction<string>) => {
            state.nextTime = payload;
        },
        setCustomerComments: (state, { payload }: PayloadAction<string>) => {
            state.customerComments = payload;
        },
        setIsValidAppointment: (state, { payload }: PayloadAction<boolean>) => {
            state.isValid = payload;
        },
        setCanSubmitAddress: (state, { payload }: PayloadAction<boolean>) => {
            state.canSubmitAddress = payload;
        },
        setAvailabilityGuid: (state, { payload }: PayloadAction<string>) => {
            state.availabilityGuid = payload;
        },
        setPickupAddress: (state, { payload }: PayloadAction<string>) => {
            state.pickupAddress = payload;
        },
        setDropoffAddress: (state, { payload }: PayloadAction<string>) => {
            state.dropoffAddress = payload;
        },
        setServiceAddress: (state, { payload }) => {
            state.serviceAddresses = payload;
        },
        setPickupServiceAddress: (state, { payload }) => {
            if (!state.serviceAddresses) {
                state.serviceAddresses = [{
                    type: '',
                    addressLine1: '',
                    cityName: '',
                    countrySubentity: '',
                    countrySubentityCode: '',
                    postalZone: '',
                }]
            }
            state.serviceAddresses[0] = payload;
        },
        setSecondaryServiceAddress: (state, {payload}) => {
            if (!state.serviceAddresses) {
                state.serviceAddresses = [{
                    type: '',
                    addressLine1: '',
                    cityName: '',
                    countrySubentity: '',
                    countrySubentityCode: '',
                    postalZone: '',
                }]
            }
            if (state.serviceAddresses.length === 1) {
                state.serviceAddresses.push({
                    type: '',
                    addressLine1: '',
                    cityName: '',
                    countrySubentity: '',
                    countrySubentityCode: '',
                    postalZone: '',
                });
            }
            // @ts-ignore
            state.serviceAddresses[1] = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAvailability.fulfilled, (state, { payload }) => {
            const { unavailableDates, availableDates } = convertAvailabilityToDates(payload);
            state.availability = availableDates;
            state.unavailableDates = unavailableDates;
            state.loading = false;
        });

        builder.addCase(fetchNextAvailability.fulfilled, (state, { payload }) => {
            if (payload.appointmentAvailabilities.DEFAULT || payload.appointmentAvailabilities.EXPRESS_SERVICE) {
                const availableDates = _.union(
                    payload.appointmentAvailabilities.DEFAULT,
                    payload.appointmentAvailabilities.EXPRESS_SERVICE
                );
                state.appointmentTime = availableDates.length ? availableDates[0].time[0] : '';
                state.appointmentDate = availableDates.length ? availableDates[0].date : '';
                state.nextTime = availableDates.length ? availableDates[0].time[0] : '';
                state.nextDate = availableDates.length ? availableDates[0].date : '';
                state.isValid = availableDates.length ? true : false;
                state.loading = false;
            }
            if (payload.availabilityGuid) {
                state.availabilityGuid = payload.availabilityGuid;
            }
        });
        builder.addCase(fetchNextAvailability.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAvailability.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAvailabilityDms.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAvailabilityDms.fulfilled, (state, { payload }) => {
            const appointmentAvailabilities = payload.payload.appointmentAvailabilities;
            if (appointmentAvailabilities?.DEFAULT || appointmentAvailabilities?.EXPRESS_SERVICE) {

                const availableDates = _.union(
                    appointmentAvailabilities.DEFAULT,
                    appointmentAvailabilities.EXPRESS_SERVICE
                );
                // @ts-ignore
                state.appointmentTime = availableDates.length && availableDates[0].time.length > 0 ? availableDates[0].time[0] : '';
                // @ts-ignore
                state.appointmentDate = availableDates.length ? availableDates[0].date : '';
                // @ts-ignore
                state.isValid = availableDates.length && availableDates[0].time.length > 0 ? true : false;
            }
            state.loading = false;
        });
        builder.addCase(fetchNextAvailability.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(fetchAvailability.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(fetchAvailabilityDms.rejected, (state, { payload }) => {
            state.loading = false;
        });
    },
});

export const AppointmentReducer = appointmentSlice.reducer;
export const {
    resetAppointment,
    setAppointmentDate,
    setAppointmentTime,
    setNextDate,
    setNextTime,
    setCustomerComments,
    setIsValidAppointment,
    setCanSubmitAddress,
    setPickupAddress,
    setPickupServiceAddress,
    setDropoffAddress,
    setServiceAddress,
    setSecondaryServiceAddress,
} = appointmentSlice.actions;
