import {
    AdditionalService,
    FilteredServices,
    NonCriticalItem,
    PriorityItem,
    ServiceNeed,
    Services,
} from '../types/Services.type';
import { LineItem } from '../types/Appointment.type';
import _ from 'lodash';
import {
    DEALERCONFIG,
    DEALER_CONFIG_ADDITIONAL,
    DEALER_CONFIG_ADDITIONAL_ORDER,
    DEALER_CONFIG_RECOMMENDED,
    DEALER_CONFIG_RECOMMENDED_ORDER,
    IVH,
    IVH_ORDER,
    MAINTENANCE_SCHEDULE,
    NONCRITICAL,
    NONCRITICAL_ORDER,
    OFFER,
    PEGA_RECALL,
    PRIORITY,
    PRIORITY_ORDER,
} from '../constants/Services';

const filterServices = (payload: Services): FilteredServices => {
    let additionalRecommendedServices: AdditionalService[] = [];
    let additionalAdditionalServices: AdditionalService[] = [];

    if (payload.recommendedServices.dealerConfigAddtlServices) {
        additionalRecommendedServices = payload.recommendedServices.dealerConfigAddtlServices.map(
            (element) => element.additionalService
        );
    }

    if (payload.additionalServices.dealerConfigAddtlServices) {
        additionalAdditionalServices = payload.additionalServices.dealerConfigAddtlServices.map(
            (element) => element.additionalService
        );
    }

    return {
        ivh: payload.recommendedServices.ivh ? payload.recommendedServices.ivh.serviceNeedList : undefined,
        pegaPriority: payload.recommendedServices.pega ? payload.recommendedServices.pega.priorityItems : undefined,
        pegaNonCritical:
            payload.recommendedServices.pega && payload.recommendedServices.pega.nonCriticalItems
                ? payload.recommendedServices.pega.nonCriticalItems.itemCategories
                : undefined,
        dealerConfigAddtlServicesRecommended: additionalRecommendedServices,
        dealerConfigAddtlServicesAdditional: additionalAdditionalServices,
    };
};

export const standardizeServices = (services: Services): LineItem[] => {
    let array: LineItem[] = [];
    let standardizedIvh: LineItem[] = [];
    let standardizedPegaPriority: LineItem[] = [];
    let standardizedPegaNonCritical: LineItem[] = [];
    let standardizedAdditionalDealerConfig: LineItem[] = [];
    let standardizedRecommendedDealerConfig: LineItem[] = [];
    const filteredServices = filterServices(services);

    if (filteredServices.ivh) {
        standardizedIvh = standardizeArray(filteredServices.ivh, 'IVH');
    }

    if (filteredServices.dealerConfigAddtlServicesRecommended) {
        standardizedRecommendedDealerConfig = standardizeArray(
            filteredServices.dealerConfigAddtlServicesRecommended,
            DEALER_CONFIG_RECOMMENDED
        );
    }

    if (filteredServices.dealerConfigAddtlServicesAdditional) {
        standardizedAdditionalDealerConfig = standardizeArray(
            filteredServices.dealerConfigAddtlServicesAdditional,
            DEALER_CONFIG_ADDITIONAL
        );
    }

    if (filteredServices.pegaPriority) {
        standardizedPegaPriority = standardizeArray(filteredServices.pegaPriority, PRIORITY);
    }

    if (filteredServices.pegaNonCritical) {
        standardizedPegaNonCritical = standardizeArray(filteredServices.pegaNonCritical, NONCRITICAL);
    }

    array = _.union(
        standardizedIvh,
        standardizedPegaPriority,
        standardizedPegaNonCritical,
        standardizedRecommendedDealerConfig,
        standardizedAdditionalDealerConfig
    );

    array = _.orderBy(array, (a) => a.secondaryOrdering, ['asc']);
    return _.orderBy(array, (a) => a.primaryOrdering, ['asc']);
};

const standardizeArray = (services: any[], type: string): LineItem[] => {
    let standardizedArray = [] as LineItem[];

    switch (type) {
        case IVH:
            standardizedArray = services.map((element, index) => {
                return standardizeIvhService(element, index, IVH_ORDER);
            });
            break;
        case PRIORITY:
            standardizedArray = services.map((element, index) => {
                return standardizePriorityService(element, index, PRIORITY_ORDER);
            });
            break;
        case NONCRITICAL:
            standardizedArray = services.map((element, index) => {
                return standardizeNonCriticalService(element, index, NONCRITICAL_ORDER);
            });
            break;
        case DEALER_CONFIG_RECOMMENDED:
            standardizedArray = services.map((element, index) => {
                return standardizeDealerConfigService(element, index, DEALER_CONFIG_RECOMMENDED_ORDER);
            });
            break;
        case DEALER_CONFIG_ADDITIONAL:
            standardizedArray = services.map((element, index) => {
                return standardizeDealerConfigService(element, index, DEALER_CONFIG_ADDITIONAL_ORDER);
            });
            break;
    }

    return standardizedArray;
};

const standardizeIvhService = (service: ServiceNeed, index: number, primaryOrdering: number): LineItem => {
    let serviceTemp = {
        code: service.campaignNumber,
        description: service.campaignDescription,
        globalLaborCode: `${service.campaignNumber}`,
        name: `Recall - ${service.campaignDescription}`,
        namePrefix: service.campaignType,
        primaryOrdering,
        secondaryOrdering: index,
        type: OFFER,
        typePlus: 'RECALL',
    };

    return serviceTemp;
};

const standardizeDealerConfigService = (
    service: AdditionalService,
    index: number,
    primaryOrdering: number
): LineItem => {
    return {
        code: service.leadCode,
        description: '',
        globalLaborCode: service.glc,
        name: service.name,
        namePrefix: DEALERCONFIG,
        primaryOrdering,
        secondaryOrdering: service.ordering,
        type: 'SERVICE',
        typePlus: 'COMMON',
    };
};

const standardizePriorityService = (service: PriorityItem, index: number, primaryOrdering: number): LineItem => {
    return {
        code: service.mfrRecallNumber,
        description: service.recallDescription,
        globalLaborCode: service.mfrRecallNumber,
        name: service.mfrRecallNumber ? 'Recall - ' + service.recallTitle : service.recallTitle,
        namePrefix: PRIORITY,
        primaryOrdering,
        secondaryOrdering: index,
        type: OFFER,
        typePlus: PEGA_RECALL,
    };
};

const standardizeNonCriticalService = (service: NonCriticalItem, index: number, primaryOrdering: number): LineItem => {
    const ncSuggestedItems = service.suggestedItems;
    const ncServiceCategoryCode = service.serviceCategoryCode;
    const ncCategoryDescription = service.categoryDescription;
    return {
        code: ncServiceCategoryCode,
        description: ncCategoryDescription,
        globalLaborCode: ncSuggestedItems[0].itemOpCode,
        name:
            ncCategoryDescription === MAINTENANCE_SCHEDULE
                ? 'Maintenance Schedule - ' + ncSuggestedItems[0].intervalDistance + ' Miles'
                : ncSuggestedItems[0].itemName,
        namePrefix: NONCRITICAL,
        primaryOrdering,
        secondaryOrdering: index,
        type: ncCategoryDescription === MAINTENANCE_SCHEDULE ? 'MAINTENANCE' : 'SERVICE',
        typePlus: ncCategoryDescription === MAINTENANCE_SCHEDULE ? 'PEGA_MAINT' : 'PEGA_DIAG',
    };
};
