import { combineReducers } from '@reduxjs/toolkit';
import { AlertReducer } from './alertSlice';
import { AppointmentReducer } from './appointmentSlice';
import { AppReducer } from './appSlice';
import { CustomerReducer } from './customerSlice';
import { ServicesReducer } from './servicesSlice';
import { TransportationReducer } from './transportationSlice';
import { VehicleReducer } from './vehicleSlice';

const rootReducer = combineReducers({
    alert: AlertReducer,
    app: AppReducer,
    appointment: AppointmentReducer,
    customer: CustomerReducer,
    services: ServicesReducer,
    transportation: TransportationReducer,
    vehicle: VehicleReducer,
});

export default rootReducer;
