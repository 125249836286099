import _ from 'lodash';

/**
 *
 * @param values: State of Formik Values
 * @param requiredFields: array of the names of required Formik Fields
 * @returns true if all required fields are populated
 * Using the any type here so I can use the values[key] syntax
 */
export const areRequiredFieldsPopulated = (values: any, requiredFields?: string[]): boolean => {
    let isPopulated = true;
    let allKeys = Object.keys(values);
    let requiredKeys;

    if (requiredFields) {
        requiredKeys = _.intersection(allKeys, requiredFields);
    } else {
        requiredKeys = allKeys;
    }

    requiredKeys.forEach((key: string) => {
        if (!values[key]) {
            isPopulated = false;
        }
    });

    return isPopulated;
};
