import { Theme } from '@material-ui/core';
import { acceleratePalette } from 'react-material-theme';

const getThemeOverride = (theme: Theme): Record<string, unknown> => {
    return {
        ...theme,
        overrides: {
            ...theme.overrides,
            MuiButton: {
                label: {
                    'text-transform': 'capitalize',
                },
                root: {
                    '&.gds-button-destructive': {
                        paddingLeft: 16,
                        paddingRight: 16,
                        backgroundColor: acceleratePalette.gmRed,
                        color: acceleratePalette.gmWhite,
                        '&:hover': {
                            backgroundColor: acceleratePalette.gmRedSecond,
                        },
                        '&:active:hover': {
                            backgroundColor: acceleratePalette.gmDarkRed,
                        },
                        '&:disabled': {
                            backgroundColor: acceleratePalette.gmPink,
                            color: acceleratePalette.gmWhite,
                            pointerEvents: 'auto',
                            '&:hover': {
                                backgroundColor: acceleratePalette.gmPink,
                                color: acceleratePalette.gmWhite,
                                pointerEvents: 'auto',
                                cursor: 'not-allowed',
                            },
                        },
                    },
                    '&.gds-button-primary': {
                        paddingLeft: 16,
                        paddingRight: 16,
                        backgroundColor: acceleratePalette.gmPrimaryBlue,
                        color: acceleratePalette.gmWhite,
                        '&:hover': {
                            backgroundColor: acceleratePalette.gmMediumBlue,
                        },
                        '&:active:hover': {
                            backgroundColor: acceleratePalette.gmDarkBlue,
                        },
                        '&:disabled': {
                            backgroundColor: acceleratePalette.gmDisabledBlue,
                            color: acceleratePalette.gmWhite,
                            pointerEvents: 'auto',
                            '&:hover': {
                                backgroundColor: acceleratePalette.gmDisabledBlue,
                                color: acceleratePalette.gmWhite,
                                pointerEvents: 'auto',
                                cursor: 'not-allowed',
                            },
                        },
                        '&.vehicle-submit-form': {
                            margin: '1rem auto 0',
                        },
                    },
                    '&.gds-button-primary-navigation': {
                        color: acceleratePalette.gmWhite,
                        height: 35,
                        width: '100%',
                        fontSize: 16,
                        fontWeight: 'normal',
                        borderRight: '0.5px solid #A3A3A3',
                        '&:hover': {
                            backgroundColor: acceleratePalette.gmDarkBlue,
                            color: acceleratePalette.gmWhite,
                        },
                        '&.active': {
                            backgroundColor: acceleratePalette.gmDarkBlue,
                            color: acceleratePalette.gmWhite,
                        },
                    },
                    '&.gds-button-secondary': {
                        paddingLeft: 16,
                        paddingRight: 16,
                        backgroundColor: acceleratePalette.gmWhite,
                        color: acceleratePalette.gmPrimaryBlue,
                        boxShadow: `inset 0 0 0 1px ${acceleratePalette.gmPrimaryBlue}`,
                        '&:hover': {
                            boxShadow: `inset 0 0 0 1px ${acceleratePalette.gmMediumBlue}`,
                            color: acceleratePalette.gmMediumBlue,
                        },
                        '&:active:hover': {
                            boxShadow: `inset 0 0 0 1px ${acceleratePalette.gmDarkBlue}`,
                            color: acceleratePalette.gmDarkBlue,
                        },
                        '&:disabled': {
                            boxShadow: `inset 0 0 0 1px ${acceleratePalette.gmDisabledBlue}`,
                            color: acceleratePalette.gmDisabledBlue,
                            pointerEvents: 'auto',
                            '&:hover': {
                                boxShadow: `inset 0 0 0 1px ${acceleratePalette.gmDisabledBlue}`,
                                color: acceleratePalette.gmDisabledBlue,
                                pointerEvents: 'auto',
                                cursor: 'not-allowed',
                            },
                        },
                    },
                    '&.gds-button-split-dropdown': {
                        minWidth: 20,
                        width: 20,
                    },
                    '&.gds-button-tertiary': {
                        paddingLeft: 16,
                        paddingRight: 16,
                        backgroundColor: 'transparent',
                        color: acceleratePalette.gmPrimaryBlue,
                        '&:hover': {
                            color: acceleratePalette.gmMediumBlue,
                        },
                        '&:active:hover': {
                            color: acceleratePalette.gmDarkBlue,
                        },
                        '&:disabled': {
                            color: acceleratePalette.gmDisabledBlue,
                            pointerEvents: 'auto',
                            '&:hover': {
                                color: acceleratePalette.gmDisabledBlue,
                                pointerEvents: 'auto',
                                cursor: 'not-allowed',
                            },
                        },
                    },
                    '&.gds-button-workflow': {
                        paddingLeft: 16,
                        paddingRight: 16,
                        backgroundColor: acceleratePalette.gmWhite,
                        color: acceleratePalette.gmPrimaryBlue,
                        boxShadow: `inset 0 0 0 1px ${acceleratePalette.gmLightGray}`,
                        '&:hover': {
                            color: acceleratePalette.gmMediumBlue,
                            backgroundColor: acceleratePalette.gmLighterGray,
                        },
                        '&:active:hover': {
                            color: acceleratePalette.gmDarkBlue,
                            backgroundColor: acceleratePalette.gmLighterGray,
                        },
                        '&:disabled': {
                            backgroundColor: acceleratePalette.gmWhite,
                            color: acceleratePalette.gmDisabledBlue,
                            pointerEvents: 'auto',
                            '&:hover': {
                                pointerEvents: 'auto',
                                cursor: 'not-allowed',
                                backgroundColor: acceleratePalette.gmWhite,
                                color: acceleratePalette.gmDisabledBlue,
                            },
                        },
                    },
                },
                outlined: {
                    '&.gds-button-group-inactive': {
                        borderColor: acceleratePalette.gmGray,
                        marginRight: '0 !important',
                        marginLeft: 0,
                    },
                    '&.gds-button-group-active': {
                        borderColor: acceleratePalette.gmPrimaryBlue,
                        backgroundColor: acceleratePalette.gmPrimaryBlue,
                        color: acceleratePalette.gmWhite,
                        marginRight: '0 !important',
                    },
                },
            },
            MuiInput: {
                root: {
                    paddingBottom: 2,
                    '&$error': {
                        color: acceleratePalette.gmDarkRed,
                    },
                },
            },
            MuiFormHelperText: {
                root: {
                    fontSize: 12,
                    '&$error': {
                        color: acceleratePalette.gmDarkRed,
                    },
                },
            },
            MuiSelect: {
                root: {
                    paddingTop: 4,
                    paddingBottom: 4,
                },
                icon: {
                    top: 'calc(50% - 14px)',
                },
            },
            MuiMenuItem: {
                gutters: {
                    '&.vehicle-menu-item-make': {
                        paddingLeft: 30,
                    },
                },
            },
            MuiListItem: {
                secondaryAction: {
                    paddingRight: 56,
                },
            },
            MuiTooltip: {
                root: {
                    fontSize: 12,
                },
            },
            MuiIconButton: {
                root: {
                    fontSize: 20,
                },
            },
            MuiDivider: {
                root: {
                    backgroundColor: acceleratePalette.gmPrimaryBlue,
                    height: 2,
                },
            },
            MuiAlert: {
                filledWarning: {
                    color: acceleratePalette.gmBlack,
                },
                message: {
                    fontSize: 14,
                },
                outlinedError: {
                    backgroundColor: acceleratePalette.gmPink,
                    color: acceleratePalette.gmBlack,
                },
                outlinedInfo: {
                    backgroundColor: acceleratePalette.gmLightestBlue,
                    color: acceleratePalette.gmBlack,
                },
                outlinedSuccess: {
                    backgroundColor: acceleratePalette.gmLightGreen,
                    color: acceleratePalette.gmBlack,
                },
                outlinedWarning: {
                    backgroundColor: acceleratePalette.gmLightYellow,
                    color: acceleratePalette.gmBlack,
                },
                root: {
                    lineHeight: '20px',
                },
            },
            MuiAlertTitle: {
                root: {
                    fontSize: 16,
                    marginTop: -1,
                },
            },
        },
        palette: {
            error: {
                main: acceleratePalette.gmDarkRed,
                contrastText: acceleratePalette.gmWhite,
            },
            info: {
                main: acceleratePalette.gmPrimaryBlue,
                contrastText: acceleratePalette.gmWhite,
            },
            primary: {
                main: acceleratePalette.gmPrimaryBlue,
                light: acceleratePalette.gmLightBlue,
                dark: acceleratePalette.gmMediumBlue,
                contrastText: acceleratePalette.gmWhite,
            },
            secondary: {
                main: acceleratePalette.gmBlack,
                light: acceleratePalette.gmLightGray,
                dark: acceleratePalette.gmDarkGray,
                contrastText: acceleratePalette.gmWhite,
            },
            success: {
                main: acceleratePalette.gmGreen,
                contrastText: acceleratePalette.gmWhite,
            },
            text: {
                primary: acceleratePalette.gmBlack,
                secondary: acceleratePalette.gmDarkGray,
            },
            warning: {
                main: acceleratePalette.gmYellow,
                contrastText: acceleratePalette.gmBlack,
            },
        },
        typography: {
            fontFamily: 'Overpass',
            h3: {
                // Section Title
                fontWeight: 700,
                fontSize: 22,
                lineHeight: '24px',
                color: acceleratePalette.gmDarkBlue,
                '@media (max-width:1279px)': {
                    fontSize: 24,
                    lineHeight: '30px',
                },
                '@media (max-width:959px)': {
                    fontSize: 24,
                    lineHeight: '30px',
                },
            },
            h4: {
                // Information Group Header
                fontWeight: 700,
                fontSize: 18,
                lineHeight: '22px',
            },
            h5: {
                // Sub-group/Paragraph/List Headings
                fontWeight: 700,
                fontSize: 16,
                lineHeight: '20px',
            },
            h6: {
                // Caption
                fontWeight: 700,
                fontSize: 14,
                lineHeight: '18px',
            },
            body: {
                // Body Copy
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '21px',
                '@media (max-width:1279px)': {
                    lineHeight: '24px',
                },
            },
            body1: {
                // Body Copy
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '21px',
                '@media (max-width:1279px)': {
                    lineHeight: '24px',
                },
            },
            body2: {
                // Legal Text (emulates the <small> tag thats defined in the style guide)
                fontWeight: 400,
                fontSize: 12,
                lineHeight: '14px',
                '@media (max-width:1279px)': {
                    fontSize: 12,
                    lineHeight: '14px',
                },
                '@media (max-width:959px)': {
                    fontSize: 14,
                    lineHeight: '18px',
                },
            },
        },
    };
};

export default getThemeOverride;
