import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Route, useLocation, Switch, Redirect } from 'react-router-dom';
import { setParams } from '../redux/slices/appSlice';
import { fetchCountryOptions, fetchSummary, fetchYears } from '../redux/asyncThunkApiMethods';
import FormBody from './FormBody/FormBody';
import Grid from '@material-ui/core/Grid';
import styles from './App.module.scss';
import Alert from './Alert/Alert';

const App = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const appState = useAppSelector((state) => state.app);
    const { search } = useLocation();

    useEffect(() => {
        if (appState.country && appState.locale && appState.bac) {
            batch(() => {
                dispatch(fetchCountryOptions({ country: appState.country, locale: appState.locale }));
                dispatch(fetchYears({ country: appState.country }));
                dispatch(fetchSummary({ country: appState.country, locale: appState.locale, bac: appState.bac }));
            });
        }
    }, [appState.bac, appState.country, appState.locale, dispatch]);

    useEffect(() => {
        dispatch(setParams(search));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles['app']} data-testid="app">
            <Grid container justifyContent="center" className={styles['app-content']}>
                <Alert />
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/iframe" />
                    </Route>
                    <Route path="/iframe" exact component={FormBody} />
                </Switch>
            </Grid>
        </div>
    );
};

export default App;
