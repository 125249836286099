import axios from 'axios';
import { Summary } from '../../types/Summary.type';

const baseURL = process.env.REACT_APP_OSS_API_BASE_URL;
const serviceName = 'summary';

export type SummaryParams = {
    bac: string;
    country: string;
    locale?: string;
};

const summaryApiService = {
    getSummary: (params: SummaryParams): Promise<Summary> => {
        const url = `${baseURL}/api/v1/${params.country}/dealership/${params.bac}/${serviceName}?locale=${params.locale}`;
        return axios.get(url).then((res) => res.data);
    },
};

export default summaryApiService;
