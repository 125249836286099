import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DefaultCustomerState = {
    email: string;
    firstName: string;
    isValid: boolean;
    lastName: string;
    phoneNumber: string;
    preferredContact: string;
};

const initialState: DefaultCustomerState = {
    email: '',
    firstName: '',
    isValid: false,
    lastName: '',
    phoneNumber: '',
    preferredContact: 'Email',
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setFirstName: (state, { payload }: PayloadAction<string>) => {
            state.firstName = payload;
        },
        setLastName: (state, { payload }: PayloadAction<string>) => {
            state.lastName = payload;
        },
        setEmail: (state, { payload }: PayloadAction<string>) => {
            state.email = payload;
        },
        setPreferredContact: (state, { payload }: PayloadAction<string>) => {
            state.preferredContact = payload;
        },
        setPhoneNumber: (state, { payload }: PayloadAction<string>) => {
            state.phoneNumber = payload;
        },
        setIsValidCustomer: (state, { payload }: PayloadAction<boolean>) => {
            state.isValid = payload;
        },
    },
});

export const CustomerReducer = customerSlice.reducer;
export const { setFirstName, setIsValidCustomer, setLastName, setEmail, setPreferredContact, setPhoneNumber } =
    customerSlice.actions;
