import React, { useEffect, useState } from 'react';
import {Grid, TextField, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {useAppSelector} from "../../redux/hooks";
import RegexParser from "regex-parser";
import {loadGoogleMapsAPI} from "./GoogleMapsApi";

interface AddressData {
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
    fullAddress: string;
}


interface TouchedFields {
    address1: boolean;
    address2: boolean;
    city: boolean;
    postalCode: boolean;
    state: boolean;
    country: boolean;
}


interface AddressFormProps {
    onChange: (data: any) => void, id: string; // Update the "any" type with the correct type if possible
}


const AddressForm: React.FC<AddressFormProps> = ({ onChange, id }) => {
    const state = useAppSelector((state) => state);
    const { countryOptions } = state.app.countryOptions;
    const country = state.app.country;
    const [addressData, setAddressData] = useState<AddressData>({
        address1: '',
        address2: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        fullAddress: ''
    });
    const [touched, setTouched] = useState<TouchedFields>({
        address1: false,
        address2: false,
        city: false,
        postalCode: false,
        state: false,
        country: false
    });



    const handlePlaceChanged = (place: any) => {
        let address1 = "";
        const fullAddress = place.formatted_address;
        if (fullAddress) {
            setAddressData((prevState) => ({ ...prevState, fullAddress }));
        }
        const submitAddress1 = () => {
            setAddressData((prevState) => ({ ...prevState, address1 }));
        }

        for (const component of place.address_components) {
            const componentType = component.types[0];
            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }
                case "route": {
                    address1 += component.short_name;
                    submitAddress1();
                    break;
                }
                case "postal_code": {
                    const postcode = `${component.short_name}`;
                    setAddressData((prevState) => ({ ...prevState, postalCode: postcode }));
                    break;
                }
                case "locality":
                    const locality = component.long_name;
                    setAddressData((prevState) => ({ ...prevState, city: locality }));
                    break;
                case "administrative_area_level_1": {
                    const state = component.short_name;
                    setAddressData((prevState) => ({ ...prevState, state }));
                    break;
                }
                case "country":
                    const country = component.short_name;
                    setAddressData((prevState) => ({ ...prevState, country }));
                    break;
            }
        }
    };

    useEffect(() => {

        const initializeAutocomplete = () => {
            loadGoogleMapsAPI().then(() => {
                // Google Maps API is loaded and available for use
                const autocompleteInput = document.getElementById(id);
                const options = {
                    types: ['address'],
                    fields: ['address_components', 'formatted_address', 'place_id', 'geometry', 'name'],
                    componentRestrictions: { country: country.toLowerCase()}
                };

                if (autocompleteInput) {
                    // @ts-ignore
                    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput, options);
                    // Use the 'autocomplete' object to set up event listeners or perform other operations
                    autocomplete.addListener('place_changed', () => {
                        const places = autocomplete.getPlace();
                        handlePlaceChanged(places);
                    });
                }
            });
        };

        initializeAutocomplete();
    }, [id, country]); // Empty dependency array to ensure useEffect runs only once

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const updateParentComponent = () => {
            onChange({
                address: addressData,
                isValidAddress:
                    isValidAddress('address1') &&
                    isValidAddress('address2') &&
                    isValidAddress('city') &&
                    isValidAddress('state') &&
                    isValidAddress('postalCode')
            });
        };

        updateParentComponent();
        // eslint-disable-next-line
    }, [addressData]);

    // Handle when user manually types the address
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setAddressData((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // Passing data to parent component when onBlur or mapSelect event occurs
    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event) {
            const { name } = event.target;
            setTouched((prevState) => ({
                ...prevState,
                [name]: true
            }));
        }
    };

    const isTouched = (fieldName: string) => {
        // @ts-ignore
        return touched[fieldName];
    };

    const displayHelperText = (item: any) => {
        if (item.name === 'address2') { // optional field
            return '';
        } else { // required field
            if (isTouched(item.name)) {
                if (!isValidAddress(item.name)) {
                    if (item.value.length === 0) {
                        return `Required`;
                    } else {
                        return `Invalid input`;
                    }
                }
            }

        }
        return '';
    };

    const isValidAddress = (componentName: string) => {
        const noSpecialChars = RegexParser(countryOptions?.ADDRESS_REGEX_CFUI);
        const cityNameRegex = RegexParser(countryOptions?.CITY_NAME_REGEX);
        const postCodeRegex = RegexParser(countryOptions?.POSTCODE_REGEX);

        switch (componentName) {
            case 'address1':
                return addressData.address1.length > 5 && !addressData.address1.match(noSpecialChars);
            case 'address2':
                return true;
            case 'city':
                return addressData.city.length > 0 && !addressData.city.match(noSpecialChars) && cityNameRegex.test(addressData.postalCode);
            case 'state':
                return addressData.state.length === 2 && !addressData.state.match(noSpecialChars);
            case 'postalCode':
                return addressData.postalCode.length >= 5 && !addressData.postalCode.match(noSpecialChars) && postCodeRegex.test(addressData.postalCode);
            default:
                return false;
        }
    };

    const getAddressHeader = (id: string) => {

        switch (id) {
            case 'SERVICE':
                return <FormattedMessage id="address.header.mobile" />;
            case 'PICK_UP':
                return <FormattedMessage id="address.header.pickup" />
            case 'DROP_OFF':
                return <FormattedMessage id="address.header.dropoff" />
            default:
                return ''
        }

    }



    const addressUIData = [
        { id: id, name: "address1", label: <FormattedMessage id="address.label.street1" />, value: `${addressData.address1}`, space: `${12}`, maxLength: `${100}` },
        { id: "", name: "address2", label: <FormattedMessage id="address.label.street2" />, value: `${addressData.address2}`, space: `${12}`, maxLength: `${50}` },
        { id: "", name: "city", label: <FormattedMessage id="address.label.city" />, value: `${addressData.city}`, space: `${6}`, maxLength: `${30}` },
        { id: "", name: "state", label: <FormattedMessage id="address.label.state" />, value: `${addressData.state}`, space: `${3}`, maxLength: `${2}` },
        { id: "", name: "postalCode", label: <FormattedMessage id="address.label.postalCode" />, value: `${addressData.postalCode}`, space: `${3}`, maxLength: `${10}` }
    ];
    return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {getAddressHeader(id)}
                    </Typography>
                </Grid>
                {addressUIData.length && addressUIData.map((item) => (
                    <Grid item key={item.name} xs={6}>
                        <Grid item key={item.name}>
                            <TextField
                                id={item.id}
                                key={item.name}
                                name={item.name}
                                fullWidth
                                className="appointment-concierge-input-pickup"
                                label={item.label}
                                value={item.value}
                                inputProps={{ maxLength: `${item.maxLength}` }}
                                onChange={handleInputChange}
                                onBlur={handleOnBlur}
                                error={isTouched(item.name) && !isValidAddress(item.name)}
                                helperText={displayHelperText(item)}
                                required={item.name !== 'address2'}

                            />
                        </Grid>
                    </Grid>

                    ))}

            </Grid>

    );
};

export default AddressForm;
