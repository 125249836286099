import axios from 'axios';
import { Services } from '../../types/Services.type';

//URL building blocks
const baseURL = process.env.REACT_APP_OSS_API_BASE_URL;
const serviceName = 'services';
const apiVersion = 'api/v1';
const dealership = 'dealership';

export type ServicesParams = {
    bac: string;
    country: string;
    locale: string;
    year: string;
    make: string;
    model: string;
    plate: string;
    vin?: string;
    mileage?: string;
    units?: string;
};

const servicesService = {
    getServices: (params: ServicesParams): Promise<Services> => {
        const url = `${baseURL}/${apiVersion}/${params.country}/${dealership}/${params.bac}/${serviceName}`;

        return axios.get(url, { params }).then((res) => res.data);
    },
};

export default servicesService;
