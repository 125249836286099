import { Field, Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Grid,
    Typography,
    Box,
    MenuItem,
    InputLabel,
    makeStyles,
    CircularProgress,
    InputAdornment,
    withStyles,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Select, TextField } from 'formik-material-ui';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import { MENU_PROPS } from '../../constants/MaterialUiConfig';
import {LocalizationProvider, DateCalendar} from '@mui/x-date-pickers';
import { batch } from 'react-redux';
import {
    setAppointmentDate,
    setAppointmentTime,
    setCustomerComments,
    setIsValidAppointment,
} from '../../redux/slices/appointmentSlice';
import { DateType } from '@date-io/type';
import { SetFormikFieldValue } from '../../types/Formik.type';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DateAvailability } from '../../types/Availability.type';
import { APPOINTMENT_DATE_FORMAT, NOTES_LENGTH } from '../../constants/Validation';
import { areRequiredFieldsPopulated } from '../../util/Validation/RequiredFields';
import PopulateAppointmentObject from '../../util/PopulateAppointmentObject';
import { fetchAvailability, fetchAvailabilityDms, fetchNextAvailability } from '../../redux/asyncThunkApiMethods';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";


const localizedFormat = require('dayjs/plugin/localizedFormat');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

interface IAppointmentInput {
    date: DateType;
    time: string;
    notes: string;
}

/**
 * Find max date that appointments can be made on
 * @param maxLookaheadDays
 * @returns the max date that can be selected
 */

// const getMaxLookaheadDate = (maxLookaheadDays: number): ParsableDate => {
//     let today = dayjs();
//
//     let maxDate = today.add(maxLookaheadDays, 'day');
//
//     return maxDate;
// };

const Appointment = (): JSX.Element => {
    const formikRef = useRef() as any;
    const state = useAppSelector((state) => state);
    const { app, appointment, services, transportation, alert } = state;
    const [lengthRemaining, setLengthRemaining] = useState(NOTES_LENGTH);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const MAX_LOOKAHEAD_DAYS = 30;
    //const MAX_LOOKAHEAD_DATE = getMaxLookaheadDate(MAX_LOOKAHEAD_DAYS);
    let notesField = {
        maxLength: NOTES_LENGTH,
    };

    useEffect(() => {
        if (alert.alert.type === 'timeslot' || alert.alert.type === 'van') {
            const appointmentObject = PopulateAppointmentObject(state);
            batch(() => {
                dispatch(
                    fetchNextAvailability({
                        bac: app.bac,
                        country: app.country,
                        appointment: appointmentObject,
                    })
                );
                dispatch(
                    fetchAvailability({
                        bac: app.bac,
                        country: app.country,
                        appointment: appointmentObject,
                        days: MAX_LOOKAHEAD_DAYS.toString(),
                    })
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert.alert.type]);

    useDeepCompareEffect(() => {
        if (formikRef.current) {
            formikRef.current.setFieldValue('date', appointment.appointmentDate);
            formikRef.current.setFieldValue('time', appointment.appointmentTime);
        }
    }, [services.selectedServices, services.serviceComments]);

    /**
     * Disable dates with no availability
     * @param date
     * @returns true if date has no availability
     */
    const disableUnavailableDates = (date: Date): boolean => {
        const day = dayjs(date).format();
        let disable: boolean;

        if (app.useDmsAvailability) {
            disable = dayjs(date).isBefore(dayjs(appointment.nextDate));
        } else {
            disable = appointment.unavailableDates.includes(day);
        }

        return disable;
    };

    const getTimeSlotsForDate = (availability: DateAvailability[], selectedDate: string): string[] => {
        let timeSlots: string[] = [];

        availability.forEach((date) => {
            if (dayjs(selectedDate).isSame(dayjs(date.date), 'day')) {
                timeSlots = date.time;
            }
        });

        return timeSlots;
    };

    const handleDateChange = (
        availability: DateAvailability[],
        date: DateType,
        setFieldValue: SetFormikFieldValue
    ) => {
        setFieldValue('date', date);
        setFieldValue('time', '');
        const appointmentObject = PopulateAppointmentObject(state);
        if (!appointmentObject.transportationOption) {
            appointmentObject.transportationOption = transportation.transportation;
        }
        batch(() => {
            dispatch(setIsValidAppointment(false));
            dispatch(
                fetchAvailabilityDms({
                    appointment: appointmentObject,
                    bac: app.bac,
                    country: app.country,
                    days: `${1}`,
                    startDate: date.format(APPOINTMENT_DATE_FORMAT)
                })
            );
        });
    };

    const schema = Yup.object({
        notes: Yup.string().trim(),
    });

    const useStyles = makeStyles({
        root: {
            textAlign: 'right',
        },
    });

    const RedTextTypography = withStyles({
        root: {
            color: 'red',
            marginTop: 9,
        },
    })(Typography);

    const helperClass = useStyles();

    const renderTimeSlots = (timeSlots: string[]): JSX.Element[] => {
        let menuItems: JSX.Element[] = [];

        if (appointment.loading) {
            menuItems.push(
                <MenuItem value="" disabled>
                    <FormattedMessage id="main.label.loading" />
                </MenuItem>
            );
        } else if (timeSlots.length) {
            menuItems = timeSlots.map((time) => (
                <MenuItem key={time} value={time}>
                    {dayjs(`${appointment.appointmentDate}${time}`).format('LT')}
                </MenuItem>
            ));
        } else {
            menuItems.push(
                <MenuItem value="" disabled>
                    <FormattedMessage id="appointment.placeholder.times" />
                </MenuItem>
            );
        }

        return menuItems;
    };

    const addressNotSubmitted = (transportation.transportation.includes('PREMIUM_CONCIERGE') || transportation.transportation.includes('MOBILE_SERVICE')) && !appointment.canSubmitAddress;

    return (
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    date: dayjs(appointment.appointmentDate) || '',
                    notes: appointment.customerComments || '',
                    time: appointment.appointmentTime || '',
                }}
                innerRef={formikRef}
                onSubmit={(values: IAppointmentInput, { setSubmitting }) => {
                    setSubmitting(false);
                    batch(() => {
                        dispatch(setAppointmentTime(values.time));
                        dispatch(setAppointmentDate(values.date.format(APPOINTMENT_DATE_FORMAT)));
                        dispatch(setCustomerComments(values.notes));
                        dispatch(setIsValidAppointment(true));
                    });
                }}
                validationSchema={schema}
            >
                {({ handleChange, handleSubmit, setFieldValue, values }) => (
                    <Form
                        data-testid="appointment"
                        onBlur={() => {
                            dispatch(setIsValidAppointment(false));
                            areRequiredFieldsPopulated(values, ['date', 'time']) && handleSubmit();
                        }}
                    >

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box mb={1}>
                                    <Typography variant="h4">
                                        <FormattedMessage id="appointment.header" />
                                    </Typography>
                                </Box>
                                {appointment.nextDate && appointment.nextTime && (
                                    <Typography variant="body2">
                                        {`${intl.formatMessage({ id: 'appointment.label.next' })}: 
                                        ${dayjs(`${appointment.nextDate}${appointment.nextTime}`).format('L LT')}`}
                                    </Typography>
                                )}
                                {appointment.nextDate && !appointment.nextTime && (
                                    <Typography variant="body2">
                                        <FormattedMessage id="next.availability.none" />
                                    </Typography>
                                )}
                                {alert.alert.type === 'timeslot' && (
                                    <RedTextTypography variant="body2">
                                        <FormattedMessage id="alert.error.timeslot" />
                                    </RedTextTypography>
                                )}
                                {alert.alert.type === 'van' && (
                                    <RedTextTypography variant="body2">
                                        <FormattedMessage id="alert.error.van" />
                                    </RedTextTypography>
                                )}
                                {alert.alert.type === 'onTrac' && (
                                    <RedTextTypography variant="body2">
                                        <FormattedMessage id="alert.error.distance" />
                                    </RedTextTypography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Field
                                        component={DateCalendar}
                                        disabled={
                                            !(services.selectedServices.length || services.serviceComments) ||
                                            app.submissionSuccessful || appointment.loading || alert.alert.severity === 'error' || addressNotSubmitted
                                        }
                                        value={appointment.appointmentDate ? dayjs(appointment.appointmentDate, "YYYY-MM-DD") : ''}
                                        disablePast
                                        disableToolbar
                                        format="L"
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'appointment.label.date' })}`}
                                        margin="normal"
                                        // maxDate={MAX_LOOKAHEAD_DATE}
                                        onChange={(date: DateType) => {
                                            handleDateChange(
                                                appointment.availability,
                                                date,
                                                setFieldValue
                                            );
                                        }}
                                        shouldDisableDate={disableUnavailableDates}
                                        name="date"
                                        variant="inline"
                                    />
                                </LocalizationProvider>
                            </Grid>
                           <Grid container item xs={6} >
                                <Grid item xs={12}>
                                    <InputLabel shrink id="time-label">
                                        <FormattedMessage id="appointment.label.time" />
                                    </InputLabel>
                                    <Field
                                        component={Select}
                                        disabled={
                                            !(services.selectedServices.length || services.serviceComments) ||
                                            app.submissionSuccessful || appointment.loading || alert.alert.severity === 'error' || addressNotSubmitted
                                        }
                                        displayEmpty
                                        fullWidth
                                        inputProps={{
                                            id: 'time-select',
                                            'data-testid': 'time-select',
                                        }}
                                        label-id="time-label"
                                        MenuProps={MENU_PROPS}
                                        name="time"
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            handleChange(event);
                                            handleSubmit();
                                        }}
                                        startAdornment={
                                            appointment.loading && (
                                                <InputAdornment position="start">
                                                    <CircularProgress size={16} />
                                                </InputAdornment>
                                            )
                                        }
                                        variant="outlined"
                                    >
                                        {renderTimeSlots(
                                            getTimeSlotsForDate(appointment.availability, appointment.appointmentDate)
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                <Field
                                    FormHelperTextProps={{
                                        classes: {
                                            root: helperClass.root,
                                        },
                                    }}
                                    component={TextField}
                                    disabled={
                                        !(services.selectedServices.length || services.serviceComments) ||
                                        app.submissionSuccessful || alert.alert.severity === 'error'
                                    }
                                    fullWidth
                                    helperText={`${lengthRemaining}/${notesField.maxLength}`}
                                    inputProps={{
                                        'data-testid': 'notes-field',
                                        id: 'notes-field',
                                        maxLength: NOTES_LENGTH,
                                    }}
                                    label={<FormattedMessage id="appointment.label.notes" />}
                                    multiline
                                    name="notes"
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        setLengthRemaining(NOTES_LENGTH - event.target.value.length);
                                        handleChange(event);
                                    }}
                                    placeholder={`${intl.formatMessage({ id: 'appointment.placeholder.notes' })}`}
                                    type="text"
                                />
                                </Grid>
                            </Grid>
                            {/*<Grid item xs={6}>
                                <Field
                                    component={DatePicker}
                                    disabled={
                                        !(services.selectedServices.length || services.serviceComments) ||
                                        app.submissionSuccessful || appointment.loading || alert.alert.severity === 'error'
                                    }
                                    disablePast
                                    disableToolbar
                                    format="L"
                                    fullWidth
                                    label={`${intl.formatMessage({ id: 'appointment.label.date' })}`}
                                    margin="normal"
                                    maxDate={MAX_LOOKAHEAD_DATE}
                                    onChange={(date: DateType) => {
                                        handleDateChange(
                                            appointment.availability,
                                            date,
                                            setFieldValue,
                                            app.useDmsAvailability
                                        );
                                    }}
                                    shouldDisableDate={disableUnavailableDates}
                                    name="date"
                                    variant="inline"
                                />
                            </Grid>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={12}>

                            </Grid>*/}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default Appointment;
