import axios from 'axios';

import { Years, Makes, Models } from '../../types/Ymmv.type';

const baseURL = process.env.REACT_APP_OSS_API_BASE_URL;
const serviceName = 'vehicle/years';
const apiVersion = 'api/v1';
const makes = 'makes';
const models = 'models';

export type YmmParams = {
    country: string;
    year?: string;
    make?: string;
    model?: string;
};

const ymmApiService = {
    getYears: (params: YmmParams): Promise<Years> => {
        const url = `${baseURL}/${apiVersion}/${params.country}/${serviceName}`;
        return axios.get(url).then((res) => res.data);
    },
    getMakes: (params: YmmParams): Promise<Makes> => {
        const url = `${baseURL}/${apiVersion}/${params.country}/${serviceName}/${params.year}/${makes}`;
        return axios.get(url).then((res) => res.data);
    },
    getModels: (params: YmmParams): Promise<Models> => {
        const url = `${baseURL}/${apiVersion}/${params.country}/${serviceName}/${params.year}/${makes}/${params.make}/${models}`;
        return axios.get(url).then((res) => res.data);
    },
};

export default ymmApiService;
