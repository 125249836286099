import { Box, Grid, InputLabel, MenuItem, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Select } from 'formik-material-ui';
import { Field, Formik, Form } from 'formik';
import { MENU_PROPS } from '../../constants/MaterialUiConfig';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setTransportation } from '../../redux/slices/transportationSlice';
import { batch } from 'react-redux';
import { ChangeEvent } from 'react';
import { fetchAvailability, fetchNextAvailability, fetchNextAvailabilityDms } from '../../redux/asyncThunkApiMethods';
import PopulateAppointmentObject from '../../util/PopulateAppointmentObject';
import { APPOINTMENT_MAX_LOOKAHEAD_DAYS } from '../../constants/Validation';
import { resetAlert } from '../../redux/slices/alertSlice';

import {
    resetAppointment,
    setAppointmentDate,
    setAppointmentTime, setCanSubmitAddress, setDropoffAddress, setNextDate, setNextTime, setPickupAddress,
    setServiceAddress
} from "../../redux/slices/appointmentSlice";

const Transportation = (): JSX.Element => {
    const state = useAppSelector((state) => state);
    const { app, services, transportation } = state;
    const dispatch = useAppDispatch();
    const MAX_LOOKAHEAD_DAYS = Object.keys(app.countryOptions).length
        ? app.countryOptions.countryOptions.MAXIMUM_APPOINTMENT_LOOKAHEAD
        : `${APPOINTMENT_MAX_LOOKAHEAD_DAYS}`;

    return (
        <Formik
            initialValues={{
                transportation: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                dispatch(resetAlert());
                const appointmentObject = PopulateAppointmentObject(state, null, values.transportation);
                const lastTransportation = transportation.transportation;
                if (!(lastTransportation && lastTransportation.includes('PREMIUM_CONCIERGE')
                    && values.transportation.includes('PREMIUM_CONCIERGE'))) {

                    dispatch(setServiceAddress([
                        {
                            type: '',
                            addressLine1: '',
                            cityName: '',
                            countrySubentity: '',
                            countrySubentityCode: '',
                            postalZone: '',
                        },
                    ]));
                    dispatch(setPickupAddress(''));
                    dispatch(setDropoffAddress(''));
                }
                dispatch(setTransportation(values.transportation));

                if (
                    !values.transportation.includes('MOBILE_SERVICE') &&
                    !values.transportation.includes('PREMIUM_CONCIERGE')
                ) {
                    batch(() => {
                        dispatch(setCanSubmitAddress(true));
                        dispatch(setPickupAddress(''));
                        dispatch(setDropoffAddress(''));
                        if (app.useDmsAvailability) {
                            dispatch(
                                fetchNextAvailabilityDms({
                                    appointment: appointmentObject,
                                    bac: app.bac,
                                    country: app.country,
                                })
                            );
                        } else {
                            dispatch(
                                fetchNextAvailability({
                                    bac: app.bac,
                                    country: app.country,
                                    appointment: appointmentObject,
                                })
                            );

                            dispatch(
                                fetchAvailability({
                                    bac: app.bac,
                                    country: app.country,
                                    appointment: appointmentObject,
                                    days: MAX_LOOKAHEAD_DAYS,
                                })
                            );
                        }
                    });
                } else {
                    dispatch(resetAppointment());
                    dispatch(setAppointmentDate(''));
                    dispatch(setAppointmentTime(''));
                    dispatch(setNextDate(''));
                    dispatch(setNextTime(''));
                    dispatch(setCanSubmitAddress(false));
                }
            }}
        >
            {({ handleChange, handleSubmit }) => (
                <Form data-testid="transportation">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                <FormattedMessage id="transportation.header" />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <InputLabel shrink id="transportation-label">
                                    <FormattedMessage id="transportation.label" />
                                </InputLabel>
                                <Field
                                    component={Select}
                                    disabled={
                                        !(services.selectedServices.length || services.serviceComments) ||
                                        app.submissionSuccessful
                                    }
                                    displayEmpty
                                    fullWidth
                                    label-id="transportation-label"
                                    inputProps={{
                                        id: 'transportation-select',
                                        'data-testid': 'transportation-select',
                                    }}
                                    MenuProps={MENU_PROPS}
                                    name="transportation"
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        handleChange(event);
                                        handleSubmit();
                                    }}
                                    variant="outlined"
                                >
                                    {transportation.transportationOptions.map((item) => (
                                        <MenuItem key={item.code} value={item.code} id={item.code}>
                                            {item.code && <FormattedMessage id={item.code} />}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default Transportation;
