export const loadGoogleMapsAPI = async () => {
    return new Promise<void>((resolve) => {
        if (window.google && window.google.maps) {
            resolve();
        } else {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`;
            script.defer = true;
            // @ts-ignore
            script.onload = resolve;
            document.body.appendChild(script);
        }
    });
};