import { FormattedMessage, useIntl } from 'react-intl';
import {
    Box,
    CircularProgress,
    Grid,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {
    setAreValidServices,
    setSelectedServices,
    setServiceArray,
    setServiceComments,
} from '../../redux/slices/servicesSlice';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { ChangeEvent, FocusEvent, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { HandleFormikChange, SetFormikFieldValue } from '../../types/Formik.type';
import { Appointment, LineItem } from '../../types/Appointment.type';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as Yup from 'yup';
import styles from './Services.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import _ from 'lodash';
import { batch } from 'react-redux';
import PopulateAppointmentObject from '../../util/PopulateAppointmentObject';
import { resetTransportation } from '../../redux/slices/transportationSlice';
import { resetAppointment } from '../../redux/slices/appointmentSlice';
import {
    fetchAvailability,
    fetchNextAvailability,
    fetchNextAvailabilityDms,
    fetchTransportationOptions,
} from '../../redux/asyncThunkApiMethods';
import dayjs from 'dayjs';
import { APPOINTMENT_MAX_LOOKAHEAD_DAYS, NOTES_LENGTH, SERVICES_SEARCH_LENGTH } from '../../constants/Validation';

interface IServiceInput {
    service?: LineItem;
    serviceComments: string;
    commentMaxLength: number;
    commentRemainingLength: number;
}

export const getTimeSlotsForDate = (date: string, availability: string[]): string[] => {
    let timeSlots: string[] = [];

    availability.forEach((appointment) => {
        if (dayjs(date).isSame(dayjs(appointment), 'day')) return appointment;
    });

    return timeSlots;
};

const isValidInput = (values: IServiceInput, selectedServices: LineItem[]): boolean => {
    let isValid: boolean;
    const { serviceComments } = values;

    if (serviceComments || selectedServices.length) {
        isValid = true;
    } else {
        isValid = false;
    }

    return isValid;
};

const Services = (): JSX.Element => {
    const { app, services } = useAppSelector((state) => state);
    const state = useAppSelector((state) => state);
    const [lengthRemaining, setLengthRemaining] = useState(NOTES_LENGTH);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const MAX_LOOKAHEAD_DAYS = Object.keys(app.countryOptions).length
        ? app.countryOptions.countryOptions.MAXIMUM_APPOINTMENT_LOOKAHEAD
        : `${APPOINTMENT_MAX_LOOKAHEAD_DAYS}`;

    let commentField = {
        maxLength: NOTES_LENGTH,
    };

    const commentSchema = Yup.object({
        serviceComments: Yup.string().trim().max(NOTES_LENGTH),
    });

    const useStyles = makeStyles({
        root: {
            textAlign: 'right',
        },
        recall: {
            color: 'red',
        },
    });
    const helperClass = useStyles();

    const combineDispatches = (appointmentObject: Appointment, isValid: boolean) => {
        const { bac, country } = app;

        dispatch(resetTransportation());
        dispatch(resetAppointment());

        dispatch(
            fetchTransportationOptions({
                bac: bac,
                country: country,
                appointment: appointmentObject,
            })
        );

        if (app.useDmsAvailability) {
            dispatch(
                fetchNextAvailabilityDms({
                    appointment: appointmentObject,
                    bac: app.bac,
                    country: app.country,
                })
            );
        } else {
            dispatch(
                fetchNextAvailability({
                    bac: bac,
                    country: country,
                    appointment: appointmentObject,
                })
            );

            dispatch(
                fetchAvailability({
                    bac: bac,
                    country: country,
                    appointment: appointmentObject,
                    days: MAX_LOOKAHEAD_DAYS,
                })
            );
        }

        dispatch(setAreValidServices(isValid));
    };

    const handleAutocomplete = (
        event: ChangeEvent<HTMLInputElement>,
        handleChange: HandleFormikChange,
        selectedService: LineItem,
        setFieldValue: SetFormikFieldValue,
        values: IServiceInput
    ) => {
        handleChange(event);
        setFieldValue('service', {
            code: '0',
        } as LineItem);

        let serviceArray = _.cloneDeep(services.serviceArray);
        let selectedArray = _.cloneDeep(services.selectedServices);
        _.remove(serviceArray, (element) => {
            return element.code === selectedService.code;
        });
        selectedArray.push(selectedService);
        selectedArray = _.orderBy(selectedArray, (a) => a.secondaryOrdering, ['asc']);
        selectedArray = _.orderBy(selectedArray, (a) => a.primaryOrdering, ['asc']);

        batch(() => {
            dispatch(setSelectedServices(selectedArray));
            dispatch(setServiceArray(serviceArray));
        });
        const appointmentObject = PopulateAppointmentObject(state, selectedArray);
        const isValid = isValidInput(values, selectedArray);
        combineDispatches(appointmentObject, isValid);
    };

    const handleDeleteService = (deletedService: LineItem, values: IServiceInput) => {
        let serviceArray = _.cloneDeep(services.serviceArray);
        let selectedArray = _.cloneDeep(services.selectedServices);
        _.remove(selectedArray, (element) => {
            return element.code === deletedService.code;
        });
        serviceArray.push(deletedService);
        serviceArray = _.orderBy(serviceArray, (a) => a.secondaryOrdering, ['asc']);
        serviceArray = _.orderBy(serviceArray, (a) => a.primaryOrdering, ['asc']);
        batch(() => {
            dispatch(setSelectedServices(selectedArray));
            dispatch(setServiceArray(serviceArray));
        });
        const isValid = isValidInput(values, selectedArray);
        const appointmentObject = PopulateAppointmentObject(state, selectedArray);
        combineDispatches(appointmentObject, isValid);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                commentMaxLength: NOTES_LENGTH,
                commentRemainingLength: NOTES_LENGTH,
                service: {
                    code: '0',
                } as LineItem,
                serviceComments: services.serviceComments || '',
            }}
            validationSchema={commentSchema}
            onSubmit={(values: IServiceInput, { setSubmitting }) => {
                setSubmitting(false);
                const appointmentObject = PopulateAppointmentObject(state, services);
                const isValid = isValidInput(values, services.selectedServices);

                batch(() => {
                    dispatch(resetTransportation());
                    dispatch(resetAppointment());
                    dispatch(setServiceComments(values.serviceComments));

                    combineDispatches(appointmentObject, isValid);
                });
            }}
        >
            {({ errors, handleChange, handleBlur, setFieldValue, touched, handleSubmit, values }) => (
                <Form data-testid="services" className={styles['app']}>
                    <Box mb={2}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Typography variant="h4">
                                    <FormattedMessage id="services.header.main" />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    arrow
                                    placement="right"
                                    title={`${intl.formatMessage({
                                        id: 'services.tooltip',
                                    })}`}
                                >
                                    <IconButton>
                                        <InfoIcon color="primary" fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                    {services.loading ? (
                        <Grid
                            alignItems="center"
                            className={styles['services-overlay']}
                            container
                            direction="column"
                            justifyContent="center"
                            spacing={5}
                        >
                            <CircularProgress />
                            <Typography variant="body1">
                                <FormattedMessage id="main.label.loading" />
                            </Typography>
                        </Grid>
                    ) : (
                        <Grow in={!services.loading}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Field
                                        clearOnBlur
                                        component={Autocomplete}
                                        disabled={!app.enableServicesSection || app.submissionSuccessful}
                                        disableClearable
                                        fullWidth
                                        getOptionLabel={(option: LineItem) => (option.name ? option.name : '')}
                                        getOptionSelected={(option: LineItem, value: LineItem) =>
                                            option.code === value.code
                                        }
                                        name="service"
                                        noOptionsText={`${intl.formatMessage({ id: 'services.label.noOptions' })}`}
                                        onChange={(event: ChangeEvent<HTMLInputElement>, selectedService: LineItem) => {
                                            handleAutocomplete(
                                                event,
                                                handleChange,
                                                selectedService,
                                                setFieldValue,
                                                values
                                            );
                                        }}
                                        options={services.serviceArray as LineItem[]}
                                        renderOption={(option: LineItem) => (
                                            <span
                                                className={
                                                    option.typePlus === 'RECALL' || option.typePlus === 'PEGA_RECALL'
                                                        ? helperClass.recall
                                                        : ''
                                                }
                                            >
                                                {option.name}
                                            </span>
                                        )}
                                        renderInput={(params: AutocompleteRenderInputParams) => (
                                            <TextField
                                                {...params}
                                                helperText={touched.service && errors.service}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    id: 'service-select',
                                                    'data-testid': 'service-select',
                                                    maxLength: SERVICES_SEARCH_LENGTH,
                                                }}
                                                placeholder={`${intl.formatMessage({
                                                    id: 'main.label.typeHere',
                                                })}`}
                                                variant="outlined"
                                            />
                                        )}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">
                                        <FormattedMessage id="services.header.selected" />
                                    </Typography>
                                    <Grid item xs={12}>
                                        <List>
                                            {services.selectedServices.length ? (
                                                services.selectedServices.map((item) => (
                                                    <ListItem alignItems="flex-start" key={item.code}>
                                                        <ListItemText>
                                                            <Typography
                                                                color={
                                                                    item.name.substring(0, 6) === 'Recall'
                                                                        ? 'error'
                                                                        : 'secondary'
                                                                }
                                                            >
                                                                {item.name}
                                                            </Typography>
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <IconButton
                                                                className={styles['icon']}
                                                                disabled={app.submissionSuccessful}
                                                                onClick={() => {
                                                                    handleDeleteService(item, values);
                                                                }}
                                                            >
                                                                <DeleteIcon color="secondary" fontSize="small" />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))
                                            ) : (
                                                <ListItem key={0}>
                                                    <ListItemText>
                                                        <FormattedMessage id="services.error.noServices" />
                                                    </ListItemText>
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        FormHelperTextProps={{
                                            classes: {
                                                root: helperClass.root,
                                            },
                                        }}
                                        component={TextField}
                                        disabled={!app.enableServicesSection || app.submissionSuccessful}
                                        fullWidth
                                        helperText={`${
                                            values.serviceComments ? lengthRemaining : values.commentMaxLength
                                        }/${commentField.maxLength}`}
                                        label={<FormattedMessage id="services.label.comments" />}
                                        inputProps={{
                                            'data-testid': 'service-comments-field',
                                            id: 'serviceComments',
                                            maxLength: NOTES_LENGTH,
                                        }}
                                        multiline
                                        name="serviceComments"
                                        onBlur={(event?: FocusEvent<HTMLInputElement>) => {
                                            handleBlur(event);
                                            handleSubmit();
                                        }}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            handleChange(event);
                                            setLengthRemaining(NOTES_LENGTH - event.target.value.length);
                                        }}
                                        placeholder={`${intl.formatMessage({
                                            id: 'services.placeholder.addComments',
                                        })}`}
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                        </Grow>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default Services;
