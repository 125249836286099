import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Years, Models, MakesFiltered } from '../../types/Ymmv.type';
import { fetchYears, fetchMakes, fetchModels } from '../asyncThunkApiMethods';

type DefaultVehicleState = {
    isValid: boolean;
    loading: boolean;
    make: string;
    makes?: MakesFiltered;
    mileage: string;
    model: string;
    models?: Models;
    vin: string;
    year: string;
    years?: Years;
};

const initialState: DefaultVehicleState = {
    isValid: false,
    loading: false,
    make: '',
    mileage: '',
    model: '',
    vin: '',
    year: '',
};

const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        setYear: (state, { payload }: PayloadAction<string>) => {
            state.year = payload;
        },
        setMake: (state, { payload }: PayloadAction<string>) => {
            state.make = payload;
        },
        setMileage: (state, { payload }: PayloadAction<string>) => {
            state.mileage = payload;
        },
        setModel: (state, { payload }: PayloadAction<string>) => {
            state.model = payload;
        },
        setVin: (state, { payload }: PayloadAction<string>) => {
            state.vin = payload;
        },
        setIsValidVehicle: (state, { payload }: PayloadAction<boolean>) => {
            state.isValid = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchYears.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchYears.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.years = payload;
        });
        builder.addCase(fetchMakes.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMakes.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.makes = payload;
        });
        builder.addCase(fetchModels.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchModels.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.models = payload;
        });
    },
});

export const VehicleReducer = vehicleSlice.reducer;
export const { setIsValidVehicle, setYear, setMake, setMileage, setModel, setVin } = vehicleSlice.actions;
