import axios from 'axios';
import { CountryOptions } from '../../types/CountryOptions.type';

const baseURL = process.env.REACT_APP_OSS_API_BASE_URL;
const serviceName = 'countryoptions';

export type CountryOptionsParams = {
    country: string;
    locale?: string;
};

const countryOptionsApiService = {
    getCountryOptions: (params: CountryOptionsParams): Promise<CountryOptions> => {
        const url = `${baseURL}/api/v1/${params.country}/${serviceName}?locale=${params.locale}`;
        return axios.get(url).then((res) => res.data);
    },
};

export default countryOptionsApiService;
