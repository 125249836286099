import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { TransportationOption } from '../../types/Options.type';
import { fetchTransportationOptions } from '../asyncThunkApiMethods';

type DefaultTransportationState = {
    loading: boolean;
    transportation: string;
    transportationOptions: TransportationOption[];
};

const initialState: DefaultTransportationState = {
    loading: false,
    transportation: '',
    transportationOptions: [{ range: '', vendorEnabled: false, code: '', displayOrder: 0 }],
};

const transportationSlice = createSlice({
    name: 'transportation',
    initialState,
    reducers: {
        setTransportation: (state, { payload }: PayloadAction<string>) => {
            state.transportation = payload;
        },
        resetTransportation: (state) => {
            state = initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTransportationOptions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTransportationOptions.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.transportationOptions = removeTransportationOptions(payload.transportationOptions);
        });
        builder.addCase(fetchTransportationOptions.rejected, (state) => {
            state.loading = false;
        });
    },
});

// Concierge transportation options are NOT shown in CAUI
const removeTransportationOptions = (transportationOptions: TransportationOption[]): TransportationOption[] => {
    let options = transportationOptions;

    _.remove(options, (element) => {
        return element.code === 'CADILLAC_PREMIUM_CONCIERGE';
    });

    options = _.orderBy(options, (a) => a.displayOrder, ['asc']);

    return options;
};

export const TransportationReducer = transportationSlice.reducer;
export const { setTransportation, resetTransportation } = transportationSlice.actions;
