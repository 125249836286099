import { Make } from '../../types/Ymmv.type';

export const verifyYear = (year: string, listOfYears: number[]) => {
    if (listOfYears.includes(Number(year))) {
        return true;
    }
};

export const verifyMake = (make: string, listOfGmMakes: Make[], listOfOtherMakes: Make[]) => {
    if (listOfGmMakes.some((gm) => gm.make.toUpperCase() === make)) {
        return true;
    } else if (listOfOtherMakes.some((other) => other.make.toUpperCase() === make)) {
        return true;
    } else {
        return false;
    }
};

export const verifyModel = (model: string, listOfModels: string[]) => {
    if (listOfModels.some((modelFromList) => modelFromList.toUpperCase() === model)) {
        return true;
    }
};
