import axios from 'axios';
import { Appointment } from '../../types/Appointment.type';
import { Options } from '../../types/Options.type';

const baseURL = process.env.REACT_APP_OSS_API_BASE_URL;
const serviceName = 'schedule/options';
const apiVersion = 'api/v4.0';
const dealerships = 'dealerships';

export type OptionsParams = {
    bac: string;
    country: string;
    appointment: Appointment;
};

const optionsApiService = {
    getOptions: (params: OptionsParams): Promise<Options> => {
        const url = `${baseURL}/oss/oss/${apiVersion}/${params.country}/${dealerships}/${params.bac}/${serviceName}`;

        return axios
            .post(url, params.appointment)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    },
};

export default optionsApiService;
