import enUS from '../lang/en-US.json';
import esMX from '../lang/es-MX.json';
import enCA from '../lang/en-CA.json';

const LanguageOptions = [
    {
        language: 'English',
        locale: 'en-US',
        fileName: enUS,
    },
    {
        language: 'English',
        locale: 'en-CA',
        fileName: enCA,
    },
    {
        language: 'Spanish',
        locale: 'es-MX',
        fileName: esMX,
    },
];

export default LanguageOptions;
