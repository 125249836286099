import axios from 'axios';
import { Appointment } from '../../types/Appointment.type';
import { Availability } from '../../types/Availability.type';


const baseURL = process.env.REACT_APP_OSS_API_BASE_URL;
const serviceName = 'available';
const apiVersion = 'api/v1';
const dealership = 'dealership';

export type AvailabilityParams = {
    appointment: Appointment;
    bac: string;
    country: string;
    days: string;
    startDate?: string;
    availabilityGuid?: string;
};

export type DmsAvailabilityParams = {
    appointment: Appointment;
    bac: string;
    country: string;
    days: string;
    startDate: string;
};

export type NextAvailabilityParams = {
    appointment: Appointment;
    bac: string;
    country: string;
    availabilityGuid?: string;
};

const availabilityApiService = {
    getAvailability: (params: AvailabilityParams): Promise<Availability> => {
        const url = `${baseURL}/${apiVersion}/${params.country}/${dealership}/${params.bac}/${serviceName}`;
        if (Number(params.days) < 5) {
            return axios
                .post(url, params.appointment, {
                    params: {
                        days: 0,
                        startDate: params.startDate,
                        availabilityGuid: params.availabilityGuid,
                    },
                })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    return err;
                });
        } else {
            return axios
                .post(url, params.appointment, {
                    params: {
                        days: 30,
                        availabilityGuid: params.availabilityGuid,
                    },
                })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    return err;
                });
        }
    },

    getNextAvailability: (params: NextAvailabilityParams): Promise<Availability> => {
        const url = `${baseURL}/${apiVersion}/${params.country}/${dealership}/${params.bac}/${serviceName}/next`;

        return axios
            .post(url, params.appointment)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    },
};

export default availabilityApiService;
